import gql from '../../_snowpack/pkg/graphql-tag.js';
export const GET_TENANT_AUTHENTICATION_METHOD = gql`
  query GET_TENANT_AUTHENTICATION_METHOD($tenantId: ID!, $identifier: String!) {
    tenant {
      get(id: $tenantId, identifier: $identifier) {
        authenticationMethod {
          catalogue
          search
        }
        staticAuthToken
      }
    }
  }
`;