import { a as __extends } from './common/tslib.es6-db19acae.js';
import { A as ApolloLink, O as Observable } from './common/bundle.esm-51e746f6.js';
import './common/_commonjsHelpers-37fa8da4.js';
import './common/bundle.esm-b46b366e.js';
import './common/process-2545f00a.js';
import './common/visitor-781df79b.js';
import './common/ast-9ee6b6e8.js';

function onError(errorHandler) {
  return new ApolloLink(function (operation, forward) {
    return new Observable(function (observer) {
      var sub;
      var retriedSub;
      var retriedResult;

      try {
        sub = forward(operation).subscribe({
          next: function (result) {
            if (result.errors) {
              retriedResult = errorHandler({
                graphQLErrors: result.errors,
                response: result,
                operation: operation,
                forward: forward
              });

              if (retriedResult) {
                retriedSub = retriedResult.subscribe({
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer)
                });
                return;
              }
            }

            observer.next(result);
          },
          error: function (networkError) {
            retriedResult = errorHandler({
              operation: operation,
              networkError: networkError,
              graphQLErrors: networkError && networkError.result && networkError.result.errors,
              forward: forward
            });

            if (retriedResult) {
              retriedSub = retriedResult.subscribe({
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer)
              });
              return;
            }

            observer.error(networkError);
          },
          complete: function () {
            if (!retriedResult) {
              observer.complete.bind(observer)();
            }
          }
        });
      } catch (e) {
        errorHandler({
          networkError: e,
          operation: operation,
          forward: forward
        });
        observer.error(e);
      }

      return function () {
        if (sub) sub.unsubscribe();
        if (retriedSub) sub.unsubscribe();
      };
    });
  });
}

(function (_super) {
  __extends(ErrorLink, _super);

  function ErrorLink(errorHandler) {
    var _this = _super.call(this) || this;

    _this.link = onError(errorHandler);
    return _this;
  }

  ErrorLink.prototype.request = function (operation, forward) {
    return this.link.request(operation, forward);
  };

  return ErrorLink;
})(ApolloLink);

export { onError };
