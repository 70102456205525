import styled from '../../../_snowpack/pkg/styled-components.js';
import { responsive, Card as C } from '../../css/index.js';
export const Card = styled(C).withConfig({
  displayName: "styles__Card",
  componentId: "ksp6i4-0"
})(["transition:all 0.2s ease-in-out;transform:translateY(0px) scale(1,1);&:hover{transform:translateY(-10px) scale(1.02,1.02);}"]);
export const Outer = styled.div.withConfig({
  displayName: "styles__Outer",
  componentId: "ksp6i4-1"
})(["display:flex;min-height:100vh;color:", ";background:", ";font-size:18px;padding:30px 25px;> *{box-sizing:border-box;}a{text-decoration:none;color:inherit;&:hover{text-decoration:underline;}}", "{padding:45px 85px;}"], ({
  theme
}) => theme.colors.frostbite, ({
  theme
}) => theme.colors.frost, responsive.smPlus);
export const Inner = styled.div.withConfig({
  displayName: "styles__Inner",
  componentId: "ksp6i4-2"
})(["width:100%;max-width:1500px;margin:0 auto;display:block;a{text-decoration:none !important;}h2{font-family:'Roboto Slab',sans-serif;color:", ";font-size:24px;margin:0;padding:0;line-height:1.1em;", "{font-size:30px;}}p{margin:0;padding:8px 0 0 0;color:", ";font-size:16px;font-weight:400;line-height:1.4em;}"], ({
  theme
}) => theme.colors.frostbite, responsive.smPlus, ({
  theme
}) => theme.colors.frostbite);
export const H1 = styled.h1.withConfig({
  displayName: "styles__H1",
  componentId: "ksp6i4-3"
})(["font-size:26px;margin:0 0 0;font-family:'Roboto Slab',sans-serif;color:", ";small{font-size:16px;font-family:'Roboto',sans-serif;line-height:1.2em;color:", ";font-weight:500;}"], ({
  theme
}) => theme.colors.frostbite, ({
  theme
}) => theme.colors.label);
export const Header = styled.header.withConfig({
  displayName: "styles__Header",
  componentId: "ksp6i4-4"
})(["border-bottom:1px solid #dfdfdf;margin-bottom:25px;padding-bottom:25px;"]);
export const Grid = styled.div.withConfig({
  displayName: "styles__Grid",
  componentId: "ksp6i4-5"
})(["display:grid;grid-template-columns:1fr 1fr;padding-top:35px;grid-gap:25px;", "{grid-template-columns:1fr;+}"], responsive.smAndLess);
export const CardInner = styled.div.withConfig({
  displayName: "styles__CardInner",
  componentId: "ksp6i4-6"
})(["padding:35px;position:relative;z-index:10;background:#fff;display:grid;align-items:center;grid-template-columns:1fr 2fr;h2,p{color:", ";}p{margin-bottom:25px;}img{width:100%;}", "{img{max-width:200px;}grid-template-columns:1fr;}"], ({
  theme
}) => theme.colors.label, responsive.xs);
export const EmptyWizard = styled.div.withConfig({
  displayName: "styles__EmptyWizard",
  componentId: "ksp6i4-7"
})(["min-height:600px;display:flex;justify-content:center;align-items:center;span{padding-left:15px;color:", ";}"], ({
  theme
}) => theme.colors.text);