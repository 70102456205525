import styled from '../../../../../_snowpack/pkg/styled-components.js';
import Magnifier from '../../../../icons/svg/magnifier.svg';
export const Wrapper = styled.div.withConfig({
  displayName: "styles__Wrapper",
  componentId: "sc-1g7yl62-0"
})(["position:fixed;bottom:0;left:0;background:", ";z-index:1;width:500px;flex-direction:column;border-top-right-radius:21px;box-shadow:1px -4px 20px rgba(130,138,144,0.1);display:none;&.isOpen{display:flex;}"], ({
  theme
}) => theme.colors.white);
export const Head = styled.div.withConfig({
  displayName: "styles__Head",
  componentId: "sc-1g7yl62-1"
})(["display:flex;align-items:center;position:relative;input{padding:15px 15px 15px 55px;border:none;border-bottom:1px solid #f1f1f1;font-family:Roboto;font-weight:500;font-size:0.88rem;line-height:1.4;color:", ";background:url(", ") no-repeat 15px center;background-size:18px;width:100%;&:focus{outline:none;}}button{border:none;cursor:pointer;margin:0 0.5rem;border-radius:50%;width:30px;height:30px;padding:0;display:flex;align-items:center;justify-content:center;background-color:transparent;&:hover,&:focus{background-color:", ";outline:none;}&:active{transform:scale(0.95);}}.loading-indicator{position:absolute;right:12%;display:none;&.visible{display:block;}}"], ({
  theme
}) => theme.colors.frostbite, Magnifier, ({
  theme
}) => theme.colors.frost);
export const List = styled.ul.withConfig({
  displayName: "styles__List",
  componentId: "sc-1g7yl62-2"
})(["list-style:none;padding-left:0;height:22rem;overflow-y:auto;"]);
export const ListItem = styled.li.withConfig({
  displayName: "styles__ListItem",
  componentId: "sc-1g7yl62-3"
})(["&[aria-selected='true']{background-color:#f3f3f3;}> a,> p{padding:5px 55px;display:flex;align-items:center;text-decoration:none;height:43.1px;> p{font-family:Roboto;font-weight:500;font-size:0.88rem;line-height:1.6;color:", ";margin:0;text-overflow:ellipsis;overflow:hidden;white-space:nowrap;> small{display:block;font-size:0.55rem;line-height:1.2;color:#aaaaaa;text-overflow:ellipsis;overflow:hidden;white-space:nowrap;}}}img{height:31px;width:auto;margin-right:0.6rem;}"], ({
  theme
}) => theme.colors.frostbite);
export const IconsWrapper = styled.div.withConfig({
  displayName: "styles__IconsWrapper",
  componentId: "sc-1g7yl62-4"
})(["padding:15px 15px 15px 55px;display:flex;align-items:center;> button > svg{pointer-events:none;}"]);
export const Button = styled.button.withConfig({
  displayName: "styles__Button",
  componentId: "sc-1g7yl62-5"
})(["border:1px solid #f1f1f1;border-radius:6px;font-family:Roboto;font-weight:500;font-size:0.88rem;line-height:1.6;color:", ";margin-right:0.5rem;padding:0.38rem;width:62px;display:flex;align-items:center;justify-content:space-between;background-color:", ";cursor:pointer;&.selected{background-color:#f3f4f6;}> svg{height:14px;width:auto;}"], ({
  theme
}) => theme.colors.frostbite, ({
  theme
}) => theme.colors.white);