import gql from '../../_snowpack/pkg/graphql-tag.js';
export const SET_DEFAULT_LANGUAGE = gql`
  mutation SET_DEFAULT_LANGUAGE($tenantId: ID!, $language: String!) {
    tenant {
      update(id: $tenantId, input: { defaults: { language: $language } }) {
        id
        defaults {
          language
        }
      }
    }
  }
`;