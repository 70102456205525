export const YOUR_CURRENT_PLAN = 'Your current plan';
export const CHOOSE_THIS_PLAN = 'Choose this plan';
export const ADD_CREDIT_CARD = 'Add credit card';
export const PARTICLE_PLAN = 'particle';
export const ATOM_PLAN = 'atom';
export const CRYSTAL_PLAN = 'crystal';
export const PAYMENT_STEPS = [{
  label: 'Plan',
  index: 0
}, {
  label: 'Customer',
  index: 1
}, {
  label: 'Payment',
  index: 2
}, {
  label: 'Finished',
  index: 3
}];