import styled from '../../_snowpack/pkg/styled-components.js';
import is from '../../_snowpack/pkg/styled-is.js';
import { colors } from './colors.js';
export const PageTitle = styled.h1.withConfig({
  displayName: "page__PageTitle",
  componentId: "sc-1v199e5-0"
})(["font-weight:600;font-size:30px;color:", ";font-family:'Roboto Slab',sans-serif;margin-bottom:20px;", ";"], colors.frostbite, is('hasIcon')`
    display:flex;
    align-items:center;
    svg, img {
      margin-right:15px;
    }
  `);