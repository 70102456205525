import gql from '../../../_snowpack/pkg/graphql-tag.js';
export const GET_TENANT_METRICS = gql`
  query GET_TENANT_METRICS($tenantId: ID!, $startDate: DateTime!, $endDate: DateTime!) {
    tenant {
      get(id: $tenantId) {
        metrics {
          items {
            count(end: $endDate)
          }
          orders {
            count(start: $startDate, end: $endDate)
          }
          webhooks {
            count(start: $startDate, end: $endDate)
          }
          apiCalls {
            count(start: $startDate, end: $endDate)
          }
          bandwidth {
            total(start: $startDate, end: $endDate, unit: GiB)
          }
        }
      }
    }
  }
`;