import styled from '../../../../_snowpack/pkg/styled-components.js';
const marginTop = '15px';
export const Pin = styled.span.withConfig({
  displayName: "styles__Pin",
  componentId: "sc-10cn5tl-0"
})(["height:25px;top:10px;width:20px;display:flex;align-items:center;cursor:pointer;overflow:hidden;opacity:0.3;position:absolute;right:0;visibility:hidden;transition:transform ease-in-out 200ms;> svg{width:10px !important;height:10px !important;path{fill:#9095a8;}}&:hover{svg path{fill:#4c505b;opacity:1;}}"]);
export const Wrapper = styled.div.withConfig({
  displayName: "styles__Wrapper",
  componentId: "sc-10cn5tl-1"
})(["--logo-height:62px;position:fixed;top:0;bottom:0;width:50px;text-align:center;z-index:1;background-color:transparent;overflow-x:hidden;transition:0.5s all cubic-bezier(0.41,0.03,0,0.96) 0s;box-shadow:0 0 10px hsla(205.7,5.9%,53.7%,0.1);@media only screen and (min-width:1920px){&:hover{&.open,&.pinned{", "{visibility:visible;}}}&.pinned{width:300px;+ main{transform:translate3d(250px,0,0);}", "{visibility:visible;transform:rotate(-45deg);}}}&.open{width:300px;+ main{transform:translate3d(250px,0,0);}}a.logo{display:flex;place-content:center;background-color:#fff;}img:not(.crystallize),svg{height:auto;min-width:22px;width:22px;}.menu{height:calc(100% - var(--logo-height) - ", ");display:flex;flex-flow:column;background-color:#fff;ul{list-style:none;padding:0;margin:0;&:first-child{margin-top:10px;}> li > svg{cursor:pointer;}}h4{margin:20px 0 8px 57px;color:#828a90;opacity:0.5;font-family:Roboto;font-size:12px;text-align:start;}.create,.manage,.build,.settings{display:flex;flex-direction:column;}.settings{margin-top:auto;}a{position:relative;height:42px;display:flex;place-content:flex-start;place-items:center;text-decoration:none;padding:0 0 0 15px;&:hover,&.active{background-color:#f3f4f6;&::before{content:'';display:block;height:100%;width:3px;background:#8fdecb;position:absolute;left:0;}}}}"], Pin, Pin, marginTop);
export const LogoContainer = styled.header.withConfig({
  displayName: "styles__LogoContainer",
  componentId: "sc-10cn5tl-2"
})(["display:flex;background-color:#fff;height:var(--logo-height);> button{padding:0;border:0;margin:0;background-color:transparent;height:var(--logo-height);flex:0 0 50px;outline-offset:-1px;}img.crystallize{height:12px;}"]);
export const Multilingual = styled.li.withConfig({
  displayName: "styles__Multilingual",
  componentId: "sc-10cn5tl-3"
})(["display:flex;padding:14px 15px;place-items:center;"]);
export const Heading5 = styled.h5.withConfig({
  displayName: "styles__Heading5",
  componentId: "sc-10cn5tl-4"
})(["font-family:Roboto;color:#4c4f5a;font-weight:500;margin:0;padding:0 0 0 20px;"]);
export const SearchWrapper = styled.li.withConfig({
  displayName: "styles__SearchWrapper",
  componentId: "sc-10cn5tl-5"
})(["position:relative;padding:0 0 0 15px;height:42px;display:flex;place-content:flex-start;place-items:center;"]);
export const Shortcut = styled.span.withConfig({
  displayName: "styles__Shortcut",
  componentId: "sc-10cn5tl-6"
})(["border:1px solid ", ";border-radius:4px;padding:2px 6px;margin-left:16px;"], ({
  theme
}) => theme.colors.white);