import { r as reactDom } from './common/index-97131f14.js';
export { r as default } from './common/index-97131f14.js';
import './common/_commonjsHelpers-37fa8da4.js';
import './common/index-ec48241a.js';



var createPortal = reactDom.createPortal;
var render = reactDom.render;
export { createPortal, render };
