let showing = false;
const el = document.getElementById('booting-text');

function hideTextElement(cb) {
  try {
    el.style.opacity = 0;
    el.style.transform = 'scale(0.5)';
  } catch (e) {}

  setTimeout(cb, 250);
}

function setTextElement(cb) {
  try {
    el.style.opacity = 1;
    el.style.transform = 'none';
  } catch (e) {}

  setTimeout(cb, 250);
}

const initialTextTimeout = setTimeout(function () {
  setText('Booting up...');
}, 1000);
export function setText(text) {
  clearTimeout(initialTextTimeout);

  (function attemptToShow() {
    if (showing) {
      setTimeout(attemptToShow, 5);
      return;
    }

    showing = true;
    hideTextElement(function () {
      try {
        el.innerText = text;
      } catch (e) {}

      setTextElement(function () {
        showing = false;
      });
    });
  })();
}
export function hide() {
  hideTextElement(function () {
    const booting = document.getElementById('booting');
    const root = document.getElementById('root');

    if (booting && root) {
      booting.style.opacity = '0';
      booting.style.transform = 'scale(1.75)';
      root.style.opacity = 1;
      root.style.transform = 'none';
      setTimeout(() => {
        document.body.style.overflow = 'auto';

        try {
          var _booting$parentElemen;

          (_booting$parentElemen = booting.parentElement) === null || _booting$parentElemen === void 0 ? void 0 : _booting$parentElemen.removeChild(booting);
        } catch (error) {
          console.error(error);
        } // Used by Cypress to detect when the app is booted


        window.__bootingDone = true;
      }, 750);
    }
  });
}