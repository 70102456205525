import PropTypes from '../../_snowpack/pkg/prop-types.js';
import styled from '../../_snowpack/pkg/styled-components.js';
import { colors } from './colors.js';
export const textConfig = {
  xs: {
    'font-size': '10px',
    'text-transform': 'uppercase'
  },
  sm: {
    'font-size': '14px'
  },
  md: {
    'font-size': '1rem'
  },
  lg: {
    'font-size': '18px'
  },
  xlg: {
    'font-size': '22px'
  }
};
const modifiers = {
  bold: p => {
    if (p.xs) {
      return `
        font-family: Roboto;
        font-weight: 400;
      `;
    }

    return `font-weight: 600;`;
  },
  upper: () => `text-transform: uppercase;`,
  slab: () => `font-family: 'Roboto Slab';`
}; // Add colors to modifiers

Object.keys(colors).forEach(color => {
  modifiers[color] = () => `color: ${colors[color]};`;
});
textConfig.__customProperties = Object.keys(textConfig).reduce((accCP, key) => {
  const type = textConfig[key];

  if (!type) {
    return [];
  }

  type.__styledStyle = Object.keys(type).reduce((acc, prop) => {
    accCP.push(`--text-${key}-${prop}: ${type[prop]};`);
    acc.push(`${prop}: var(--text-${key}-${prop});`);
    return acc;
  }, []).join('');
  return accCP;
}, []);

function setModifiers(props) {
  const mods = [];
  Object.keys(modifiers).forEach(key => {
    if (key in props) {
      mods.push(modifiers[key](props));
    }
  });
  return mods.join('');
}

function setTypes(props) {
  const mods = [];
  Object.keys(textConfig).forEach(key => {
    if (key in props) {
      mods.push(textConfig[key].__styledStyle);
    }
  });
  return mods.join('');
}

export const Text = styled.span.withConfig({
  displayName: "text__Text",
  componentId: "qoifbw-0"
})(["", ";", ";"], setTypes, setModifiers); // Calculate the prop types

const calculatedPropTypes = {};
[...Object.keys(textConfig), ...Object.keys(modifiers)].forEach(key => calculatedPropTypes[key] = PropTypes.bool);
Text.propTypes = calculatedPropTypes;