export let CustomerAddressType;

(function (CustomerAddressType) {
  CustomerAddressType["delivery"] = "delivery";
  CustomerAddressType["billing"] = "billing";
  CustomerAddressType["other"] = "other";
})(CustomerAddressType || (CustomerAddressType = {}));

export let SubscriptionChange;

(function (SubscriptionChange) {
  SubscriptionChange[SubscriptionChange["UpgradeParticleToAtomWithPayment"] = 0] = "UpgradeParticleToAtomWithPayment";
  SubscriptionChange[SubscriptionChange["UpgradeParticleToAtomWithoutPayment"] = 1] = "UpgradeParticleToAtomWithoutPayment";
  SubscriptionChange[SubscriptionChange["DowngradeAtomToParticleWithPayment"] = 2] = "DowngradeAtomToParticleWithPayment";
  SubscriptionChange[SubscriptionChange["DowngradeAtomToParticleWithoutPayment"] = 3] = "DowngradeAtomToParticleWithoutPayment";
  SubscriptionChange[SubscriptionChange["CreateNewSubscription"] = 4] = "CreateNewSubscription";
  SubscriptionChange[SubscriptionChange["AddPaymentToCurrentSubscription"] = 5] = "AddPaymentToCurrentSubscription";
  SubscriptionChange[SubscriptionChange["KeepCurrentPlanUnchanged"] = 6] = "KeepCurrentPlanUnchanged";
})(SubscriptionChange || (SubscriptionChange = {}));