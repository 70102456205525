import styled from '../../../../../_snowpack/pkg/styled-components.js';
import is from '../../../../../_snowpack/pkg/styled-is.js';
export const List = styled.ul.withConfig({
  displayName: "styles__List",
  componentId: "sc-1y44h7g-0"
})(["list-style:none;margin:0;border-bottom:1px solid #f5f5f5;padding:0 0 0.25rem;"]);
export const ListItem = styled.li.withConfig({
  displayName: "styles__ListItem",
  componentId: "sc-1y44h7g-1"
})(["font-size:14px;font-family:Roboto;display:flex;place-items:center;margin:0 -0.5rem;&:hover{background-color:rgba(0,0,0,0.03);}.name{padding:0.5rem 0.5rem 0.5rem 1.5rem;cursor:pointer;}.code{padding:0 0 0 0.5rem;text-transform:uppercase;font-weight:700;font-size:14px;line-height:1.6;color:#828a90;}", " &[aria-selected=true]{background-color:rgba(0,0,0,0.03);}"], is('isActive')`
    .name {
      background-color: rgba(0,0,0,0.03);
    }
  `);
export const Heading = styled.h6.withConfig({
  displayName: "styles__Heading",
  componentId: "sc-1y44h7g-2"
})(["font-family:Roboto;font-weight:700;font-size:12px;line-height:1.2;color:#9095a8;margin:0 0 1rem;"]);
export const Filter = styled.div.withConfig({
  displayName: "styles__Filter",
  componentId: "sc-1y44h7g-3"
})(["margin:0.25rem 0 0;padding:0.5rem 0 0;label{text-transform:uppercase;font-weight:700;font-size:14px;line-height:1.6;color:#828a90;}input{margin:0 0 0 1.5rem;background-color:transparent;border:none;color:#4c4f5a;font-family:'Roboto';font-size:14px;}"]);