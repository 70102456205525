import styled from '../../../_snowpack/pkg/styled-components.js'; // @ts-ignore

import { colors } from '../../css/index.js';
export const FullPageOuter = styled.div.withConfig({
  displayName: "styles__FullPageOuter",
  componentId: "q2v9aq-0"
})(["height:100vh;display:flex;flex-direction:column;align-items:center;justify-content:center;padding:0 30px;background:#fafafa;"]);
export const Heading = styled.h1.withConfig({
  displayName: "styles__Heading",
  componentId: "q2v9aq-1"
})(["font-family:'Roboto Slab',sans-serif;font-size:26px;color:", ";margin-bottom:8px;text-align:center;"], colors.frostbite);
export const SubHeading = styled.div.withConfig({
  displayName: "styles__SubHeading",
  componentId: "q2v9aq-2"
})(["margin:30px 0;"]);
export const ImageWrap = styled.div.withConfig({
  displayName: "styles__ImageWrap",
  componentId: "q2v9aq-3"
})(["text-align:center;margin-bottom:30px;> img{width:320px;height:320px;}"]);
export const Content = styled.div.withConfig({
  displayName: "styles__Content",
  componentId: "q2v9aq-4"
})(["text-align:center;"]);