import { useState } from '../../_snowpack/pkg/react.js';
import config from '../app-config.js';

function useLogout() {
  const [loggingOut, setLoggingOut] = useState(false);

  const logOut = () => {
    setLoggingOut(true);
    window.location.href = config.SIGN_OUT_URL;
  };

  return {
    loggingOut,
    logOut
  };
}

export default useLogout;