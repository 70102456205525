import { c as createCommonjsModule } from './common/_commonjsHelpers-37fa8da4.js';
import { r as react } from './common/index-ec48241a.js';

var reactSwitch_min = createCommonjsModule(function (module, exports) {
Object.defineProperty(exports, "__esModule", {
  value: !0
});



function i() {
  return (i = Object.assign || function (t) {
    for (var i = 1; i < arguments.length; i++) {
      var s = arguments[i];

      for (var n in s) Object.prototype.hasOwnProperty.call(s, n) && (t[n] = s[n]);
    }

    return t;
  }).apply(this, arguments);
}

var s = react.createElement("svg", {
  viewBox: "-2 -5 14 20",
  height: "100%",
  width: "100%",
  style: {
    position: "absolute",
    top: 0
  }
}, react.createElement("path", {
  d: "M9.9 2.12L7.78 0 4.95 2.828 2.12 0 0 2.12l2.83 2.83L0 7.776 2.123 9.9 4.95 7.07 7.78 9.9 9.9 7.776 7.072 4.95 9.9 2.12",
  fill: "#fff",
  fillRule: "evenodd"
})),
    n = react.createElement("svg", {
  height: "100%",
  width: "100%",
  viewBox: "-2 -5 17 21",
  style: {
    position: "absolute",
    top: 0
  }
}, react.createElement("path", {
  d: "M11.264 0L5.26 6.004 2.103 2.847 0 4.95l5.26 5.26 8.108-8.107L11.264 0",
  fill: "#fff",
  fillRule: "evenodd"
}));

function o(t) {
  if (7 === t.length) return t;

  for (var i = "#", s = 1; s < 4; s += 1) i += t[s] + t[s];

  return i;
}

function h(t, i, s, n, h) {
  return function (t, i, s, n, o) {
    var h = (t - s) / (i - s);
    if (0 === h) return n;
    if (1 === h) return o;

    for (var e = "#", a = 1; a < 6; a += 2) {
      var r = parseInt(n.substr(a, 2), 16),
          l = parseInt(o.substr(a, 2), 16),
          u = Math.round((1 - h) * r + h * l).toString(16);
      1 === u.length && (u = "0" + u), e += u;
    }

    return e;
  }(t, i, s, o(n), o(h));
}

var e = function (s) {
  function n(t) {
    s.call(this, t);
    var i = t.height,
        n = t.width,
        o = t.checked;
    this.t = t.handleDiameter || i - 2, this.i = Math.max(n - i, n - (i + this.t) / 2), this.o = Math.max(0, (i - this.t) / 2), this.state = {
      h: o ? this.i : this.o
    }, this.l = 0, this.u = 0, this.p = this.p.bind(this), this.v = this.v.bind(this), this.g = this.g.bind(this), this.k = this.k.bind(this), this.M = this.M.bind(this), this.m = this.m.bind(this), this.T = this.T.bind(this), this.$ = this.$.bind(this), this.C = this.C.bind(this), this.D = this.D.bind(this), this.O = this.O.bind(this), this.S = this.S.bind(this);
  }

  return s && (n.__proto__ = s), (n.prototype = Object.create(s && s.prototype)).constructor = n, n.prototype.componentDidMount = function () {
    this.W = !0;
  }, n.prototype.componentDidUpdate = function (t) {
    t.checked !== this.props.checked && this.setState({
      h: this.props.checked ? this.i : this.o
    });
  }, n.prototype.componentWillUnmount = function () {
    this.W = !1;
  }, n.prototype.I = function (t) {
    this.H.focus(), this.setState({
      R: t,
      j: !0,
      B: Date.now()
    });
  }, n.prototype.L = function (t) {
    var i = this.state,
        s = i.R,
        n = i.h,
        o = (this.props.checked ? this.i : this.o) + t - s;
    i.N || t === s || this.setState({
      N: !0
    });
    var h = Math.min(this.i, Math.max(this.o, o));
    h !== n && this.setState({
      h: h
    });
  }, n.prototype.U = function (t) {
    var i = this.state,
        s = i.h,
        n = i.N,
        o = i.B,
        h = this.props.checked,
        e = (this.i + this.o) / 2;
    this.setState({
      h: this.props.checked ? this.i : this.o
    });
    var a = Date.now() - o;
    (!n || a < 250 || h && s <= e || !h && s >= e) && this.A(t), this.W && this.setState({
      N: !1,
      j: !1
    }), this.l = Date.now();
  }, n.prototype.p = function (t) {
    t.preventDefault(), "number" == typeof t.button && 0 !== t.button || (this.I(t.clientX), window.addEventListener("mousemove", this.v), window.addEventListener("mouseup", this.g));
  }, n.prototype.v = function (t) {
    t.preventDefault(), this.L(t.clientX);
  }, n.prototype.g = function (t) {
    this.U(t), window.removeEventListener("mousemove", this.v), window.removeEventListener("mouseup", this.g);
  }, n.prototype.k = function (t) {
    this.X = null, this.I(t.touches[0].clientX);
  }, n.prototype.M = function (t) {
    this.L(t.touches[0].clientX);
  }, n.prototype.m = function (t) {
    t.preventDefault(), this.U(t);
  }, n.prototype.$ = function (t) {
    Date.now() - this.l > 50 && (this.A(t), Date.now() - this.u > 50 && this.W && this.setState({
      j: !1
    }));
  }, n.prototype.C = function () {
    this.u = Date.now();
  }, n.prototype.D = function () {
    this.setState({
      j: !0
    });
  }, n.prototype.O = function () {
    this.setState({
      j: !1
    });
  }, n.prototype.S = function (t) {
    this.H = t;
  }, n.prototype.T = function (t) {
    t.preventDefault(), this.H.focus(), this.A(t), this.W && this.setState({
      j: !1
    });
  }, n.prototype.A = function (t) {
    var i = this.props;
    (0, i.onChange)(!i.checked, t, i.id);
  }, n.prototype.render = function () {
    var s = this.props,
        n = s.checked,
        o = s.disabled,
        e = s.className,
        a = s.offColor,
        r = s.onColor,
        l = s.offHandleColor,
        u = s.onHandleColor,
        c = s.checkedIcon,
        d = s.uncheckedIcon,
        f = s.checkedHandleIcon,
        p = s.uncheckedHandleIcon,
        b = s.boxShadow,
        v = s.activeBoxShadow,
        g = s.height,
        y = s.width,
        w = s.borderRadius,
        k = function (t, i) {
      var s = {};

      for (var n in t) Object.prototype.hasOwnProperty.call(t, n) && -1 === i.indexOf(n) && (s[n] = t[n]);

      return s;
    }(s, ["checked", "disabled", "className", "offColor", "onColor", "offHandleColor", "onHandleColor", "checkedIcon", "uncheckedIcon", "checkedHandleIcon", "uncheckedHandleIcon", "boxShadow", "activeBoxShadow", "height", "width", "borderRadius", "handleDiameter"]),
        M = this.state,
        m = M.h,
        T = M.N,
        x = M.j,
        $ = {
      position: "relative",
      display: "inline-block",
      textAlign: "left",
      opacity: o ? .5 : 1,
      direction: "ltr",
      borderRadius: g / 2,
      WebkitTransition: "opacity 0.25s",
      MozTransition: "opacity 0.25s",
      transition: "opacity 0.25s",
      touchAction: "none",
      WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
      WebkitUserSelect: "none",
      MozUserSelect: "none",
      msUserSelect: "none",
      userSelect: "none"
    },
        C = {
      height: g,
      width: y,
      margin: Math.max(0, (this.t - g) / 2),
      position: "relative",
      background: h(m, this.i, this.o, a, r),
      borderRadius: "number" == typeof w ? w : g / 2,
      cursor: o ? "default" : "pointer",
      WebkitTransition: T ? null : "background 0.25s",
      MozTransition: T ? null : "background 0.25s",
      transition: T ? null : "background 0.25s"
    },
        D = {
      height: g,
      width: Math.min(1.5 * g, y - (this.t + g) / 2 + 1),
      position: "relative",
      opacity: (m - this.o) / (this.i - this.o),
      pointerEvents: "none",
      WebkitTransition: T ? null : "opacity 0.25s",
      MozTransition: T ? null : "opacity 0.25s",
      transition: T ? null : "opacity 0.25s"
    },
        O = {
      height: g,
      width: Math.min(1.5 * g, y - (this.t + g) / 2 + 1),
      position: "absolute",
      opacity: 1 - (m - this.o) / (this.i - this.o),
      right: 0,
      top: 0,
      pointerEvents: "none",
      WebkitTransition: T ? null : "opacity 0.25s",
      MozTransition: T ? null : "opacity 0.25s",
      transition: T ? null : "opacity 0.25s"
    },
        S = {
      height: this.t,
      width: this.t,
      background: h(m, this.i, this.o, l, u),
      display: "inline-block",
      cursor: o ? "default" : "pointer",
      borderRadius: "number" == typeof w ? w - 1 : "50%",
      position: "absolute",
      transform: "translateX(" + m + "px)",
      top: Math.max(0, (g - this.t) / 2),
      outline: 0,
      boxShadow: x ? v : b,
      border: 0,
      WebkitTransition: T ? null : "background-color 0.25s, transform 0.25s, box-shadow 0.15s",
      MozTransition: T ? null : "background-color 0.25s, transform 0.25s, box-shadow 0.15s",
      transition: T ? null : "background-color 0.25s, transform 0.25s, box-shadow 0.15s"
    },
        W = {
      height: this.t,
      width: this.t,
      opacity: Math.max(2 * (1 - (m - this.o) / (this.i - this.o) - .5), 0),
      position: "absolute",
      left: 0,
      top: 0,
      pointerEvents: "none",
      WebkitTransition: T ? null : "opacity 0.25s",
      MozTransition: T ? null : "opacity 0.25s",
      transition: T ? null : "opacity 0.25s"
    },
        z = {
      height: this.t,
      width: this.t,
      opacity: Math.max(2 * ((m - this.o) / (this.i - this.o) - .5), 0),
      position: "absolute",
      left: 0,
      top: 0,
      pointerEvents: "none",
      WebkitTransition: T ? null : "opacity 0.25s",
      MozTransition: T ? null : "opacity 0.25s",
      transition: T ? null : "opacity 0.25s"
    };

    return react.createElement("div", {
      className: e,
      style: $
    }, react.createElement("div", {
      className: "react-switch-bg",
      style: C,
      onClick: o ? null : this.T,
      onMouseDown: function (t) {
        return t.preventDefault();
      }
    }, c && react.createElement("div", {
      style: D
    }, c), d && react.createElement("div", {
      style: O
    }, d)), react.createElement("div", {
      className: "react-switch-handle",
      style: S,
      onClick: function (t) {
        return t.preventDefault();
      },
      onMouseDown: o ? null : this.p,
      onTouchStart: o ? null : this.k,
      onTouchMove: o ? null : this.M,
      onTouchEnd: o ? null : this.m,
      onTouchCancel: o ? null : this.O
    }, p && react.createElement("div", {
      style: W
    }, p), f && react.createElement("div", {
      style: z
    }, f)), react.createElement("input", i({}, {
      type: "checkbox",
      role: "switch",
      "aria-checked": n,
      checked: n,
      disabled: o,
      style: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        width: 1
      }
    }, k, {
      ref: this.S,
      onFocus: this.D,
      onBlur: this.O,
      onKeyUp: this.C,
      onChange: this.$
    })));
  }, n;
}(react.Component);

e.defaultProps = {
  disabled: !1,
  offColor: "#888",
  onColor: "#080",
  offHandleColor: "#fff",
  onHandleColor: "#fff",
  uncheckedIcon: s,
  checkedIcon: n,
  boxShadow: null,
  activeBoxShadow: "0 0 2px 3px #3bf",
  height: 28,
  width: 56
}, exports.default = e;
});

var reactSwitch = createCommonjsModule(function (module) {
{
  module.exports = reactSwitch_min;
}
});

export default reactSwitch;
