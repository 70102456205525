import { useEffect } from '../../../_snowpack/pkg/react.js';
import { useTenant } from '../../hooks/index.js';

function DocumentTitle({
  title
}) {
  const {
    tenant
  } = useTenant();
  useEffect(() => {
    var _tenant$name;

    const tenantPart = (_tenant$name = tenant === null || tenant === void 0 ? void 0 : tenant.name) !== null && _tenant$name !== void 0 ? _tenant$name : 'Crystallize';
    const titlePart = title !== '' ? `${title} | ` : '';
    const documentTitle = `${titlePart}${tenantPart}`;
    document.title = documentTitle;
  }, [title, tenant]);
  return null;
}

export default DocumentTitle;