import { useContext } from '../../_snowpack/pkg/react.js';
import { useQuery } from '../../_snowpack/pkg/@apollo/react-hooks.js';
import { GET_USER_ROLE } from '../queries/index.js';
import { UserContext } from './user-context.js';

function useUserRole() {
  var _data$user$get;

  const {
    tenant: {
      id: tenantId
    },
    user: {
      id: userId
    }
  } = useContext(UserContext);
  const {
    data
  } = useQuery(GET_USER_ROLE, {
    variables: {
      userId,
      tenantId
    }
  });
  const {
    role
  } = (data === null || data === void 0 ? void 0 : (_data$user$get = data.user.get) === null || _data$user$get === void 0 ? void 0 : _data$user$get.role) || {};
  return role;
}

export default useUserRole;