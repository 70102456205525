import cloneDeep from '../../../_snowpack/pkg/lodash/cloneDeep.js';

function validateFunctions({
  onChange,
  onDropCallback,
  monitor
}) {
  if (onChange) {
    if (typeof onChange !== 'function') {
      throw new Error('onChange is not a function');
    }
  }

  if (onDropCallback) {
    if (typeof onDropCallback !== 'function') {
      throw new Error('onDropCallback is not a function');
    }
  }

  if (monitor && typeof monitor !== 'object') {
    throw new Error('monitor is not an object');
  }
}

function validateItem(item) {
  if (!item) {
    throw new Error('Item is not defined');
  }

  if (typeof item !== 'object') {
    throw new Error('Item should be an object');
  }

  if (!item.hasOwnProperty('remove')) {
    throw new Error('Item should have a property remove');
  }

  if (typeof item.remove !== 'function') {
    throw new Error('Item.remove should be a function');
  }
}

function validateItems(items) {
  if (!items) {
    throw new Error('Items are not defined');
  }

  if (!Array.isArray(items)) {
    throw new Error('Items must be an array');
  }
}

function validateIndexes({
  fromIndex,
  toIndex
}) {
  if (typeof fromIndex !== 'number') {
    throw new Error('fromIndex is not defined or is not a number');
  }

  if (typeof toIndex !== 'number') {
    throw new Error('toIndex is not defined or is not a number');
  }
}

function dropHelper({
  item,
  items,
  monitor,
  removeItem,
  onChange,
  onDropCallback,
  DROP_CONTAINER_ID
}) {
  validateFunctions({
    onChange,
    onDropCallback,
    monitor
  });
  validateItem(item);
  validateItems(items);
  const mediaItem = item.item || {};
  const sameParent = DROP_CONTAINER_ID === item.parentId; // Return if it's already the last item in the current list

  if (sameParent && items.findIndex(i => i.id === mediaItem.id) === items.length - 1) {
    return;
  } // Push it to the end of the items


  const updatedItems = [...items.filter(i => i.id !== mediaItem.id), mediaItem];

  if (!sameParent) {
    // Remove from the external container
    item.remove(); // Update the DnD item with new parent info

    item.remove = () => removeItem(mediaItem.id);

    item.index = updatedItems.length - 1;
    item.parentId = DROP_CONTAINER_ID;
  }

  onChange === null || onChange === void 0 ? void 0 : onChange(updatedItems); // call the callback function after the item it's dropped

  onDropCallback === null || onDropCallback === void 0 ? void 0 : onDropCallback(); // return the item to know if it's needed to remote it from previous parent

  return item;
}

function reorderItemsHelper({
  fromIndex,
  toIndex,
  items,
  onChange
}) {
  validateFunctions({
    onChange
  });
  validateItems(items);
  validateIndexes({
    fromIndex,
    toIndex
  }); // get the dragged item

  const dragItem = items[fromIndex];
  const updatedItems = cloneDeep(items); // remove the dragged item from the list

  updatedItems.splice(fromIndex, 1); // put it back in the dropped place

  updatedItems.splice(toIndex, 0, dragItem);
  onChange === null || onChange === void 0 ? void 0 : onChange(updatedItems);
}

export { dropHelper, reorderItemsHelper };