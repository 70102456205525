import gql from '../../_snowpack/pkg/graphql-tag.js';
export const GET_USER_ROLE = gql`
  query GET_USER_ROLE($tenantId: ID!, $userId: ID!) {
    user {
      get(id: $userId) {
        id
        role(tenantId: $tenantId) {
          role
        }
      }
    }
  }
`;