import styled from '../../_snowpack/pkg/styled-components.js';
import { colors } from './colors.js';
export const H1 = styled.h1.withConfig({
  displayName: "typography__H1",
  componentId: "sc-1eqgha7-0"
})(["font-size:30px;font-family:'Roboto Slab',serif;font-weight:400;color:", ";"], colors.frostbite);
export const H2 = styled.h2.withConfig({
  displayName: "typography__H2",
  componentId: "sc-1eqgha7-1"
})(["font-size:24px;margin-bottom:20px;font-family:'Roboto Slab',serif;font-weight:400;color:", ";"], colors.frostbite);
export const H3 = styled.h3.withConfig({
  displayName: "typography__H3",
  componentId: "sc-1eqgha7-2"
})(["font-size:20px;margin-bottom:20px;font-family:'Roboto Slab',serif;font-weight:300;color:", ";"], colors.frostbite);
export const H4 = styled.h4.withConfig({
  displayName: "typography__H4",
  componentId: "sc-1eqgha7-3"
})(["font-size:17px;margin-bottom:20px;font-family:'Roboto Slab',serif;font-weight:300;color:", ";"], colors.frostbite);
export const P = styled.p.withConfig({
  displayName: "typography__P",
  componentId: "sc-1eqgha7-4"
})([""]);
export const Bold = styled.b.withConfig({
  displayName: "typography__Bold",
  componentId: "sc-1eqgha7-5"
})(["color:", ";"], colors.frostbite);