import styled from '../../../_snowpack/pkg/styled-components.js';
export const Arrow = styled.div.withConfig({
  displayName: "styles__Arrow",
  componentId: "sc-1eto0qj-0"
})(["&,&::before{position:absolute;width:8px;height:8px;z-index:-1;}&::before{content:'';transform:rotate(45deg);background-color:", ";}"], ({
  theme
}) => theme.colors.white);
export const Wrapper = styled.div.withConfig({
  displayName: "styles__Wrapper",
  componentId: "sc-1eto0qj-1"
})(["align-items:center;background-color:", ";border-radius:6px;box-shadow:1px 1px 4px rgba(0,0,0,0.1),-1px -1px 4px rgba(0,0,0,0.15);display:flex;font-size:12px;justify-content:space-evenly;min-height:34px;min-width:100px;padding:0.5rem;visibility:hidden;z-index:99;&[data-theme='dark']{color:", ";background-color:#333;> ", "{&::before{background-color:#333;}}}&[data-popper-placement^='top'] > ", "{bottom:-4px;}&[data-popper-placement^='bottom'] > ", "{top:-4px;}&[data-popper-placement^='left'] > ", "{right:-4px;}&[data-popper-placement^='right'] > ", "{left:-4px;}&[data-show='true'],&[data-permanent='true']{visibility:visible;}"], ({
  theme
}) => theme.colors.white, ({
  theme
}) => theme.colors.white, Arrow, Arrow, Arrow, Arrow, Arrow);