import { useQuery } from '../../_snowpack/pkg/@apollo/react-hooks.js';
import { GET_PRICE_VARIANTS } from '../queries/index.js';
import useTenant from './useTenant.js';

function usePriceVariants() {
  var _data$priceVariant$ge, _data$priceVariant;

  const {
    tenant: {
      id: tenantId
    }
  } = useTenant();
  const {
    error,
    data
  } = useQuery(GET_PRICE_VARIANTS, {
    variables: {
      tenantId
    }
  });
  const priceVariants = (_data$priceVariant$ge = data === null || data === void 0 ? void 0 : (_data$priceVariant = data.priceVariant) === null || _data$priceVariant === void 0 ? void 0 : _data$priceVariant.getMany) !== null && _data$priceVariant$ge !== void 0 ? _data$priceVariant$ge : [];

  if (error) {
    console.error(`Couldn't retrieve tenant price variants`, error);
  } // Put the default price variant in first place


  return priceVariants === null || priceVariants === void 0 ? void 0 : priceVariants.reduce((acc, val) => {
    if ((val === null || val === void 0 ? void 0 : val.identifier) === 'default') {
      acc === null || acc === void 0 ? void 0 : acc.unshift(val);
    } else {
      acc === null || acc === void 0 ? void 0 : acc.push(val);
    }

    return acc;
  }, []);
}

export default usePriceVariants;