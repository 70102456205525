import { ApolloClient } from '../../_snowpack/pkg/apollo-client.js';
import { ApolloLink } from '../../_snowpack/pkg/apollo-link.js';
import { HttpLink } from '../../_snowpack/pkg/apollo-link-http.js';
import { onError } from '../../_snowpack/pkg/apollo-link-error.js';
import { defaultDataIdFromObject, InMemoryCache, IntrospectionFragmentMatcher } from '../../_snowpack/pkg/apollo-cache-inmemory.js';
import { removePropertiesFromObject } from '../helper.js';
import config from '../app-config.js';
import { setText } from '../booting.js';
import { explainError } from './api-error-explainer.js';
import introspectionQueryResultData from '../graphql.schema.json';

function removePropertiesNotCompatibleWithMutation(obj) {
  return removePropertiesFromObject(['__typename'], obj);
}

const addAccessToken = config.ACCESS_TOKEN_ID && config.ACCESS_TOKEN_SECRET;
const uri = config.GRAPH_URL; // Strip __typename from variables

const parseMutationData = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = removePropertiesNotCompatibleWithMutation(operation.variables);
  }

  return forward(operation);
});
const cache = new InMemoryCache({
  dataIdFromObject: object => {
    var _object$version;

    switch (object.__typename) {
      case 'Product':
      case 'Item':
      case 'Document':
      case 'Folder':
      case 'Grid':
        // We need to specify which version it is in order to avoid having the same createdAt date
        return `${object.__typename}:${object.language}:${object.id}:${((_object$version = object.version) === null || _object$version === void 0 ? void 0 : _object$version.label) || 'current'}`;

      case 'Topic':
        return `${object.__typename}:${object.language}:${object.id}`;

      case 'Shape':
        return `${object.__typename}:${object.identifier}:${object.name}:${object.type}`;

      case 'ShapeComponent':
        return `${object.__typename}:${object.id}:${object.name}:${object.type}`;

      default:
        return defaultDataIdFromObject(object);
      // fall back to default handling
    }
  },
  fragmentMatcher: new IntrospectionFragmentMatcher({
    introspectionQueryResultData
  })
}); // set text on the loading screen

setText('Loading...');
let client;
export function setupClient({
  onNotAuthenticated,
  onAuthenticated
}) {
  let onAuthenticatedCalled = false;
  const link = new HttpLink({
    credentials: 'include',
    uri,
    fetch: async (uri, options) => {
      const response = await fetch(uri, options);

      if (response.ok && !onAuthenticatedCalled) {
        onAuthenticated();
        onAuthenticatedCalled = true;
      }

      return response;
    },
    ...(addAccessToken && {
      headers: {
        'X-Crystallize-Access-Token-Id': config.ACCESS_TOKEN_ID,
        'X-Crystallize-Access-Token-Secret': config.ACCESS_TOKEN_SECRET
      }
    })
  }); // Check if the session is expired or not created

  let onNotAuthenticatedCalled = false;
  const logoutLink = onError(({
    networkError,
    graphQLErrors,
    response,
    operation
  }) => {
    if (networkError && (networkError.statusCode === 403 || networkError.statusCode === 401)) {
      if (!onNotAuthenticatedCalled) {
        onNotAuthenticated();
        onNotAuthenticatedCalled = true;
      }

      return;
    }

    if (response && graphQLErrors) {
      const explainers = graphQLErrors.map(explainError).filter(e => !!e); // the error is handled at the invite token component level

      if (operation.operationName === 'REDEEM_INVITE_TOKEN') {
        return;
      }

      if (explainers) {
        response.errorsExplained = explainers; // Prevent Apollo for throwing an error

        response.errors = undefined;
      }
    }
  });
  client = new ApolloClient({
    link: ApolloLink.from([logoutLink, parseMutationData, link]),
    cache,
    defaultOptions: {
      query: {
        fetchPolicy: 'network-only'
      }
    }
  });
  return client;
}
export function getClient() {
  return client;
}