import gql from '../../_snowpack/pkg/graphql-tag.js';
export const SubscriptionPlan = gql`
  fragment SubscriptionPlan on SubscriptionPlan {
    identifier
    name
    periods {
      id
      name
      initial {
        period
        unit
      }
      recurring {
        period
        unit
      }
    }
    meteredVariables {
      id
      identifier
      name
      unit
    }
  }
`;
export const GET_SUBSCRIPTION_PLANS = gql`
  query GET_SUBSCRIPTION_PLANS($tenantId: ID!) {
    subscriptionPlan {
      getMany(tenantId: $tenantId) {
        ...SubscriptionPlan
      }
    }
  }

  ${SubscriptionPlan}
`;