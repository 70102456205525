// function validateEndItem(item) {
//   if (typeof item !== 'object') {
//     throw new Error('Item is not defined or is not an object');
//   }
//   if (!item.hasOwnProperty('id')) {
//     throw new Error('Item must have a property: id');
//   }
//   if (!item.hasOwnProperty('remove')) {
//     throw new Error('Item must have a property: remove');
//   }
//   if (typeof item.remove !== 'function') {
//     throw new Error('item.remove parameter is not a function');
//   }
//   if (!item.hasOwnProperty('type')) {
//     throw new Error('Item must have a property: type');
//   }
//   if (typeof item.type !== 'string') {
//     throw new Error('item.remove parameter is not a string');
//   }
// }
// function validateMonitor(monitor) {
//   if (typeof monitor !== 'object') {
//     throw new Error('monitor parameter is not defined or is not an object');
//   }
// }
function validateParameters({
  item,
  index,
  parentId,
  reorderItems
}) {
  if (typeof item !== 'object') {
    throw new Error('Item is not defined or is not an object');
  }

  if (!item.hasOwnProperty('index')) {
    throw new Error('Item must have a property: index');
  }

  if (!item.hasOwnProperty('parentId')) {
    throw new Error('Item must have a property: parentId');
  }

  if (!parentId) {
    throw new Error('ParentId parameter is not defined');
  }

  if (typeof index !== 'number') {
    throw new Error('Index parameter is not defined');
  }

  if (typeof reorderItems !== 'function') {
    throw new Error('reorderItems parameter is not a function');
  }
}

export function hover({
  item,
  index,
  parentId,
  removeItem,
  insertItem,
  reorderItems
}) {
  validateParameters({
    item,
    index,
    parentId,
    reorderItems
  });
  const mediaItem = item.item; // External item coming in

  if (item.parentId !== parentId) {
    // Remove it from the external list
    item.remove(); // Insert the item in this new container

    insertItem({
      index,
      item: mediaItem
    }); // Update the DnD item with new parent info

    item.remove = () => removeItem(mediaItem.id);

    item.parentId = parentId;
    item.index = index;
    return;
  }

  const fromIndex = item.index;
  const toIndex = index; // Don't replace the item with themselves

  if (fromIndex === toIndex) {
    return;
  }

  reorderItems({
    fromIndex,
    toIndex
  }); // Note: we're mutating the monitor item here!
  // Generally it's better to avoid mutations,
  // but it's good here for the sake of performance
  // to avoid expensive index searches.

  item.index = toIndex;
}