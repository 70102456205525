import gql from '../../_snowpack/pkg/graphql-tag.js';
export const tenantBasicInfoFragment = gql`
  fragment tenantBasicInfo on Tenant {
    id
    rootItemId
    identifier
    name
    defaults {
      language
    }
  }
`;
export const GET_ME_AND_TENANTS = gql`
  query GET_ME_AND_TENANTS {
    me {
      id
      firstName
      lastName
      email
      isAdmin
      tocReadAt
      companyName
      marketingEmailConsentedAt
      tenants {
        tenant {
          ...tenantBasicInfo
        }
      }
    }
  }
  ${tenantBasicInfoFragment}
`;