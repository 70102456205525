import { a as __extends, b as __assign, d as __awaiter, e as __generator } from './tslib.es6-db19acae.js';
import { I as InvariantError, i as invariant, t as tryFunctionOrLogError, c as cloneDeep, g as getOperationDefinition, m as mergeDeep, h as hasDirectives, r as removeClientSetsFromDocument, b as buildQueryFromSelectionSet, a as canUseWeakMap, d as removeConnectionDirectiveFromDocument, e as hasClientExports, f as getDefaultValues, j as graphQLResultHasError, k as getOperationName, l as getMainDefinition, n as getFragmentDefinitions, o as createFragmentMap, p as mergeDeepArray, q as resultKeyNameFromField, s as argumentsObjectFromField, u as shouldInclude, v as isField, w as isInlineFragment } from './bundle.esm-b46b366e.js';
import { e as execute, O as Observable$1, A as ApolloLink } from './bundle.esm-51e746f6.js';
import { r as result } from './index-2f784aea.js';
import { v as visit, B as BREAK } from './visitor-781df79b.js';
import { e as equal } from './equality.esm-5339f780.js';

var NetworkStatus;

(function (NetworkStatus) {
  NetworkStatus[NetworkStatus["loading"] = 1] = "loading";
  NetworkStatus[NetworkStatus["setVariables"] = 2] = "setVariables";
  NetworkStatus[NetworkStatus["fetchMore"] = 3] = "fetchMore";
  NetworkStatus[NetworkStatus["refetch"] = 4] = "refetch";
  NetworkStatus[NetworkStatus["poll"] = 6] = "poll";
  NetworkStatus[NetworkStatus["ready"] = 7] = "ready";
  NetworkStatus[NetworkStatus["error"] = 8] = "error";
})(NetworkStatus || (NetworkStatus = {}));

function isNetworkRequestInFlight(networkStatus) {
  return networkStatus < 7;
}

var Observable = function (_super) {
  __extends(Observable, _super);

  function Observable() {
    return _super !== null && _super.apply(this, arguments) || this;
  }

  Observable.prototype[result] = function () {
    return this;
  };

  Observable.prototype['@@observable'] = function () {
    return this;
  };

  return Observable;
}(Observable$1);

function isNonEmptyArray(value) {
  return Array.isArray(value) && value.length > 0;
}

function isApolloError(err) {
  return err.hasOwnProperty('graphQLErrors');
}

var generateErrorMessage = function (err) {
  var message = '';

  if (isNonEmptyArray(err.graphQLErrors)) {
    err.graphQLErrors.forEach(function (graphQLError) {
      var errorMessage = graphQLError ? graphQLError.message : 'Error message not found.';
      message += "GraphQL error: " + errorMessage + "\n";
    });
  }

  if (err.networkError) {
    message += 'Network error: ' + err.networkError.message + '\n';
  }

  message = message.replace(/\n$/, '');
  return message;
};

var ApolloError = function (_super) {
  __extends(ApolloError, _super);

  function ApolloError(_a) {
    var graphQLErrors = _a.graphQLErrors,
        networkError = _a.networkError,
        errorMessage = _a.errorMessage,
        extraInfo = _a.extraInfo;

    var _this = _super.call(this, errorMessage) || this;

    _this.graphQLErrors = graphQLErrors || [];
    _this.networkError = networkError || null;

    if (!errorMessage) {
      _this.message = generateErrorMessage(_this);
    } else {
      _this.message = errorMessage;
    }

    _this.extraInfo = extraInfo;
    _this.__proto__ = ApolloError.prototype;
    return _this;
  }

  return ApolloError;
}(Error);

var FetchType;

(function (FetchType) {
  FetchType[FetchType["normal"] = 1] = "normal";
  FetchType[FetchType["refetch"] = 2] = "refetch";
  FetchType[FetchType["poll"] = 3] = "poll";
})(FetchType || (FetchType = {}));

var hasError = function (storeValue, policy) {
  if (policy === void 0) {
    policy = 'none';
  }

  return storeValue && (storeValue.networkError || policy === 'none' && isNonEmptyArray(storeValue.graphQLErrors));
};

var ObservableQuery = function (_super) {
  __extends(ObservableQuery, _super);

  function ObservableQuery(_a) {
    var queryManager = _a.queryManager,
        options = _a.options,
        _b = _a.shouldSubscribe,
        shouldSubscribe = _b === void 0 ? true : _b;

    var _this = _super.call(this, function (observer) {
      return _this.onSubscribe(observer);
    }) || this;

    _this.observers = new Set();
    _this.subscriptions = new Set();
    _this.isTornDown = false;
    _this.options = options;
    _this.variables = options.variables || {};
    _this.queryId = queryManager.generateQueryId();
    _this.shouldSubscribe = shouldSubscribe;
    var opDef = getOperationDefinition(options.query);
    _this.queryName = opDef && opDef.name && opDef.name.value;
    _this.queryManager = queryManager;
    return _this;
  }

  ObservableQuery.prototype.result = function () {
    var _this = this;

    return new Promise(function (resolve, reject) {
      var observer = {
        next: function (result) {
          resolve(result);

          _this.observers.delete(observer);

          if (!_this.observers.size) {
            _this.queryManager.removeQuery(_this.queryId);
          }

          setTimeout(function () {
            subscription.unsubscribe();
          }, 0);
        },
        error: reject
      };

      var subscription = _this.subscribe(observer);
    });
  };

  ObservableQuery.prototype.currentResult = function () {
    var result = this.getCurrentResult();

    if (result.data === undefined) {
      result.data = {};
    }

    return result;
  };

  ObservableQuery.prototype.getCurrentResult = function () {
    if (this.isTornDown) {
      var lastResult = this.lastResult;
      return {
        data: !this.lastError && lastResult && lastResult.data || void 0,
        error: this.lastError,
        loading: false,
        networkStatus: NetworkStatus.error
      };
    }

    var _a = this.queryManager.getCurrentQueryResult(this),
        data = _a.data,
        partial = _a.partial;

    var queryStoreValue = this.queryManager.queryStore.get(this.queryId);
    var result;
    var fetchPolicy = this.options.fetchPolicy;
    var isNetworkFetchPolicy = fetchPolicy === 'network-only' || fetchPolicy === 'no-cache';

    if (queryStoreValue) {
      var networkStatus = queryStoreValue.networkStatus;

      if (hasError(queryStoreValue, this.options.errorPolicy)) {
        return {
          data: void 0,
          loading: false,
          networkStatus: networkStatus,
          error: new ApolloError({
            graphQLErrors: queryStoreValue.graphQLErrors,
            networkError: queryStoreValue.networkError
          })
        };
      }

      if (queryStoreValue.variables) {
        this.options.variables = __assign(__assign({}, this.options.variables), queryStoreValue.variables);
        this.variables = this.options.variables;
      }

      result = {
        data: data,
        loading: isNetworkRequestInFlight(networkStatus),
        networkStatus: networkStatus
      };

      if (queryStoreValue.graphQLErrors && this.options.errorPolicy === 'all') {
        result.errors = queryStoreValue.graphQLErrors;
      }
    } else {
      var loading = isNetworkFetchPolicy || partial && fetchPolicy !== 'cache-only';
      result = {
        data: data,
        loading: loading,
        networkStatus: loading ? NetworkStatus.loading : NetworkStatus.ready
      };
    }

    if (!partial) {
      this.updateLastResult(__assign(__assign({}, result), {
        stale: false
      }));
    }

    return __assign(__assign({}, result), {
      partial: partial
    });
  };

  ObservableQuery.prototype.isDifferentFromLastResult = function (newResult) {
    var snapshot = this.lastResultSnapshot;
    return !(snapshot && newResult && snapshot.networkStatus === newResult.networkStatus && snapshot.stale === newResult.stale && equal(snapshot.data, newResult.data));
  };

  ObservableQuery.prototype.getLastResult = function () {
    return this.lastResult;
  };

  ObservableQuery.prototype.getLastError = function () {
    return this.lastError;
  };

  ObservableQuery.prototype.resetLastResults = function () {
    delete this.lastResult;
    delete this.lastResultSnapshot;
    delete this.lastError;
    this.isTornDown = false;
  };

  ObservableQuery.prototype.resetQueryStoreErrors = function () {
    var queryStore = this.queryManager.queryStore.get(this.queryId);

    if (queryStore) {
      queryStore.networkError = null;
      queryStore.graphQLErrors = [];
    }
  };

  ObservableQuery.prototype.refetch = function (variables) {
    var fetchPolicy = this.options.fetchPolicy;

    if (fetchPolicy === 'cache-only') {
      return Promise.reject(new InvariantError(1) );
    }

    if (fetchPolicy !== 'no-cache' && fetchPolicy !== 'cache-and-network') {
      fetchPolicy = 'network-only';
    }

    if (!equal(this.variables, variables)) {
      this.variables = __assign(__assign({}, this.variables), variables);
    }

    if (!equal(this.options.variables, this.variables)) {
      this.options.variables = __assign(__assign({}, this.options.variables), this.variables);
    }

    return this.queryManager.fetchQuery(this.queryId, __assign(__assign({}, this.options), {
      fetchPolicy: fetchPolicy
    }), FetchType.refetch);
  };

  ObservableQuery.prototype.fetchMore = function (fetchMoreOptions) {
    var _this = this;

    invariant(fetchMoreOptions.updateQuery, 2) ;

    var combinedOptions = __assign(__assign({}, fetchMoreOptions.query ? fetchMoreOptions : __assign(__assign(__assign({}, this.options), fetchMoreOptions), {
      variables: __assign(__assign({}, this.variables), fetchMoreOptions.variables)
    })), {
      fetchPolicy: 'network-only'
    });

    var qid = this.queryManager.generateQueryId();
    return this.queryManager.fetchQuery(qid, combinedOptions, FetchType.normal, this.queryId).then(function (fetchMoreResult) {
      _this.updateQuery(function (previousResult) {
        return fetchMoreOptions.updateQuery(previousResult, {
          fetchMoreResult: fetchMoreResult.data,
          variables: combinedOptions.variables
        });
      });

      _this.queryManager.stopQuery(qid);

      return fetchMoreResult;
    }, function (error) {
      _this.queryManager.stopQuery(qid);

      throw error;
    });
  };

  ObservableQuery.prototype.subscribeToMore = function (options) {
    var _this = this;

    var subscription = this.queryManager.startGraphQLSubscription({
      query: options.document,
      variables: options.variables
    }).subscribe({
      next: function (subscriptionData) {
        var updateQuery = options.updateQuery;

        if (updateQuery) {
          _this.updateQuery(function (previous, _a) {
            var variables = _a.variables;
            return updateQuery(previous, {
              subscriptionData: subscriptionData,
              variables: variables
            });
          });
        }
      },
      error: function (err) {
        if (options.onError) {
          options.onError(err);
          return;
        }
      }
    });
    this.subscriptions.add(subscription);
    return function () {
      if (_this.subscriptions.delete(subscription)) {
        subscription.unsubscribe();
      }
    };
  };

  ObservableQuery.prototype.setOptions = function (opts) {
    var oldFetchPolicy = this.options.fetchPolicy;
    this.options = __assign(__assign({}, this.options), opts);

    if (opts.pollInterval) {
      this.startPolling(opts.pollInterval);
    } else if (opts.pollInterval === 0) {
      this.stopPolling();
    }

    var fetchPolicy = opts.fetchPolicy;
    return this.setVariables(this.options.variables, oldFetchPolicy !== fetchPolicy && (oldFetchPolicy === 'cache-only' || oldFetchPolicy === 'standby' || fetchPolicy === 'network-only'), opts.fetchResults);
  };

  ObservableQuery.prototype.setVariables = function (variables, tryFetch, fetchResults) {
    if (tryFetch === void 0) {
      tryFetch = false;
    }

    if (fetchResults === void 0) {
      fetchResults = true;
    }

    this.isTornDown = false;
    variables = variables || this.variables;

    if (!tryFetch && equal(variables, this.variables)) {
      return this.observers.size && fetchResults ? this.result() : Promise.resolve();
    }

    this.variables = this.options.variables = variables;

    if (!this.observers.size) {
      return Promise.resolve();
    }

    return this.queryManager.fetchQuery(this.queryId, this.options);
  };

  ObservableQuery.prototype.updateQuery = function (mapFn) {
    var queryManager = this.queryManager;

    var _a = queryManager.getQueryWithPreviousResult(this.queryId),
        previousResult = _a.previousResult,
        variables = _a.variables,
        document = _a.document;

    var newResult = tryFunctionOrLogError(function () {
      return mapFn(previousResult, {
        variables: variables
      });
    });

    if (newResult) {
      queryManager.dataStore.markUpdateQueryResult(document, variables, newResult);
      queryManager.broadcastQueries();
    }
  };

  ObservableQuery.prototype.stopPolling = function () {
    this.queryManager.stopPollingQuery(this.queryId);
    this.options.pollInterval = undefined;
  };

  ObservableQuery.prototype.startPolling = function (pollInterval) {
    assertNotCacheFirstOrOnly(this);
    this.options.pollInterval = pollInterval;
    this.queryManager.startPollingQuery(this.options, this.queryId);
  };

  ObservableQuery.prototype.updateLastResult = function (newResult) {
    var previousResult = this.lastResult;
    this.lastResult = newResult;
    this.lastResultSnapshot = this.queryManager.assumeImmutableResults ? newResult : cloneDeep(newResult);
    return previousResult;
  };

  ObservableQuery.prototype.onSubscribe = function (observer) {
    var _this = this;

    try {
      var subObserver = observer._subscription._observer;

      if (subObserver && !subObserver.error) {
        subObserver.error = defaultSubscriptionObserverErrorCallback;
      }
    } catch (_a) {}

    var first = !this.observers.size;
    this.observers.add(observer);
    if (observer.next && this.lastResult) observer.next(this.lastResult);
    if (observer.error && this.lastError) observer.error(this.lastError);

    if (first) {
      this.setUpQuery();
    }

    return function () {
      if (_this.observers.delete(observer) && !_this.observers.size) {
        _this.tearDownQuery();
      }
    };
  };

  ObservableQuery.prototype.setUpQuery = function () {
    var _this = this;

    var _a = this,
        queryManager = _a.queryManager,
        queryId = _a.queryId;

    if (this.shouldSubscribe) {
      queryManager.addObservableQuery(queryId, this);
    }

    if (this.options.pollInterval) {
      assertNotCacheFirstOrOnly(this);
      queryManager.startPollingQuery(this.options, queryId);
    }

    var onError = function (error) {
      _this.updateLastResult(__assign(__assign({}, _this.lastResult), {
        errors: error.graphQLErrors,
        networkStatus: NetworkStatus.error,
        loading: false
      }));

      iterateObserversSafely(_this.observers, 'error', _this.lastError = error);
    };

    queryManager.observeQuery(queryId, this.options, {
      next: function (result) {
        if (_this.lastError || _this.isDifferentFromLastResult(result)) {
          var previousResult_1 = _this.updateLastResult(result);

          var _a = _this.options,
              query_1 = _a.query,
              variables = _a.variables,
              fetchPolicy_1 = _a.fetchPolicy;

          if (queryManager.transform(query_1).hasClientExports) {
            queryManager.getLocalState().addExportedVariables(query_1, variables).then(function (variables) {
              var previousVariables = _this.variables;
              _this.variables = _this.options.variables = variables;

              if (!result.loading && previousResult_1 && fetchPolicy_1 !== 'cache-only' && queryManager.transform(query_1).serverQuery && !equal(previousVariables, variables)) {
                _this.refetch();
              } else {
                iterateObserversSafely(_this.observers, 'next', result);
              }
            });
          } else {
            iterateObserversSafely(_this.observers, 'next', result);
          }
        }
      },
      error: onError
    }).catch(onError);
  };

  ObservableQuery.prototype.tearDownQuery = function () {
    var queryManager = this.queryManager;
    this.isTornDown = true;
    queryManager.stopPollingQuery(this.queryId);
    this.subscriptions.forEach(function (sub) {
      return sub.unsubscribe();
    });
    this.subscriptions.clear();
    queryManager.removeObservableQuery(this.queryId);
    queryManager.stopQuery(this.queryId);
    this.observers.clear();
  };

  return ObservableQuery;
}(Observable);

function defaultSubscriptionObserverErrorCallback(error) {
}

function iterateObserversSafely(observers, method, argument) {
  var observersWithMethod = [];
  observers.forEach(function (obs) {
    return obs[method] && observersWithMethod.push(obs);
  });
  observersWithMethod.forEach(function (obs) {
    return obs[method](argument);
  });
}

function assertNotCacheFirstOrOnly(obsQuery) {
  var fetchPolicy = obsQuery.options.fetchPolicy;
  invariant(fetchPolicy !== 'cache-first' && fetchPolicy !== 'cache-only', 3) ;
}

var MutationStore = function () {
  function MutationStore() {
    this.store = {};
  }

  MutationStore.prototype.getStore = function () {
    return this.store;
  };

  MutationStore.prototype.get = function (mutationId) {
    return this.store[mutationId];
  };

  MutationStore.prototype.initMutation = function (mutationId, mutation, variables) {
    this.store[mutationId] = {
      mutation: mutation,
      variables: variables || {},
      loading: true,
      error: null
    };
  };

  MutationStore.prototype.markMutationError = function (mutationId, error) {
    var mutation = this.store[mutationId];

    if (mutation) {
      mutation.loading = false;
      mutation.error = error;
    }
  };

  MutationStore.prototype.markMutationResult = function (mutationId) {
    var mutation = this.store[mutationId];

    if (mutation) {
      mutation.loading = false;
      mutation.error = null;
    }
  };

  MutationStore.prototype.reset = function () {
    this.store = {};
  };

  return MutationStore;
}();

var QueryStore = function () {
  function QueryStore() {
    this.store = {};
  }

  QueryStore.prototype.getStore = function () {
    return this.store;
  };

  QueryStore.prototype.get = function (queryId) {
    return this.store[queryId];
  };

  QueryStore.prototype.initQuery = function (query) {
    var previousQuery = this.store[query.queryId];
    invariant(!previousQuery || previousQuery.document === query.document || equal(previousQuery.document, query.document), 19) ;
    var isSetVariables = false;
    var previousVariables = null;

    if (query.storePreviousVariables && previousQuery && previousQuery.networkStatus !== NetworkStatus.loading) {
      if (!equal(previousQuery.variables, query.variables)) {
        isSetVariables = true;
        previousVariables = previousQuery.variables;
      }
    }

    var networkStatus;

    if (isSetVariables) {
      networkStatus = NetworkStatus.setVariables;
    } else if (query.isPoll) {
      networkStatus = NetworkStatus.poll;
    } else if (query.isRefetch) {
      networkStatus = NetworkStatus.refetch;
    } else {
      networkStatus = NetworkStatus.loading;
    }

    var graphQLErrors = [];

    if (previousQuery && previousQuery.graphQLErrors) {
      graphQLErrors = previousQuery.graphQLErrors;
    }

    this.store[query.queryId] = {
      document: query.document,
      variables: query.variables,
      previousVariables: previousVariables,
      networkError: null,
      graphQLErrors: graphQLErrors,
      networkStatus: networkStatus,
      metadata: query.metadata
    };

    if (typeof query.fetchMoreForQueryId === 'string' && this.store[query.fetchMoreForQueryId]) {
      this.store[query.fetchMoreForQueryId].networkStatus = NetworkStatus.fetchMore;
    }
  };

  QueryStore.prototype.markQueryResult = function (queryId, result, fetchMoreForQueryId) {
    if (!this.store || !this.store[queryId]) return;
    this.store[queryId].networkError = null;
    this.store[queryId].graphQLErrors = isNonEmptyArray(result.errors) ? result.errors : [];
    this.store[queryId].previousVariables = null;
    this.store[queryId].networkStatus = NetworkStatus.ready;

    if (typeof fetchMoreForQueryId === 'string' && this.store[fetchMoreForQueryId]) {
      this.store[fetchMoreForQueryId].networkStatus = NetworkStatus.ready;
    }
  };

  QueryStore.prototype.markQueryError = function (queryId, error, fetchMoreForQueryId) {
    if (!this.store || !this.store[queryId]) return;
    this.store[queryId].networkError = error;
    this.store[queryId].networkStatus = NetworkStatus.error;

    if (typeof fetchMoreForQueryId === 'string') {
      this.markQueryResultClient(fetchMoreForQueryId, true);
    }
  };

  QueryStore.prototype.markQueryResultClient = function (queryId, complete) {
    var storeValue = this.store && this.store[queryId];

    if (storeValue) {
      storeValue.networkError = null;
      storeValue.previousVariables = null;

      if (complete) {
        storeValue.networkStatus = NetworkStatus.ready;
      }
    }
  };

  QueryStore.prototype.stopQuery = function (queryId) {
    delete this.store[queryId];
  };

  QueryStore.prototype.reset = function (observableQueryIds) {
    var _this = this;

    Object.keys(this.store).forEach(function (queryId) {
      if (observableQueryIds.indexOf(queryId) < 0) {
        _this.stopQuery(queryId);
      } else {
        _this.store[queryId].networkStatus = NetworkStatus.loading;
      }
    });
  };

  return QueryStore;
}();

function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

var LocalState = function () {
  function LocalState(_a) {
    var cache = _a.cache,
        client = _a.client,
        resolvers = _a.resolvers,
        fragmentMatcher = _a.fragmentMatcher;
    this.cache = cache;

    if (client) {
      this.client = client;
    }

    if (resolvers) {
      this.addResolvers(resolvers);
    }

    if (fragmentMatcher) {
      this.setFragmentMatcher(fragmentMatcher);
    }
  }

  LocalState.prototype.addResolvers = function (resolvers) {
    var _this = this;

    this.resolvers = this.resolvers || {};

    if (Array.isArray(resolvers)) {
      resolvers.forEach(function (resolverGroup) {
        _this.resolvers = mergeDeep(_this.resolvers, resolverGroup);
      });
    } else {
      this.resolvers = mergeDeep(this.resolvers, resolvers);
    }
  };

  LocalState.prototype.setResolvers = function (resolvers) {
    this.resolvers = {};
    this.addResolvers(resolvers);
  };

  LocalState.prototype.getResolvers = function () {
    return this.resolvers || {};
  };

  LocalState.prototype.runResolvers = function (_a) {
    var document = _a.document,
        remoteResult = _a.remoteResult,
        context = _a.context,
        variables = _a.variables,
        _b = _a.onlyRunForcedResolvers,
        onlyRunForcedResolvers = _b === void 0 ? false : _b;
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_c) {
        if (document) {
          return [2, this.resolveDocument(document, remoteResult.data, context, variables, this.fragmentMatcher, onlyRunForcedResolvers).then(function (localResult) {
            return __assign(__assign({}, remoteResult), {
              data: localResult.result
            });
          })];
        }

        return [2, remoteResult];
      });
    });
  };

  LocalState.prototype.setFragmentMatcher = function (fragmentMatcher) {
    this.fragmentMatcher = fragmentMatcher;
  };

  LocalState.prototype.getFragmentMatcher = function () {
    return this.fragmentMatcher;
  };

  LocalState.prototype.clientQuery = function (document) {
    if (hasDirectives(['client'], document)) {
      if (this.resolvers) {
        return document;
      }
    }

    return null;
  };

  LocalState.prototype.serverQuery = function (document) {
    return this.resolvers ? removeClientSetsFromDocument(document) : document;
  };

  LocalState.prototype.prepareContext = function (context) {
    if (context === void 0) {
      context = {};
    }

    var cache = this.cache;

    var newContext = __assign(__assign({}, context), {
      cache: cache,
      getCacheKey: function (obj) {
        if (cache.config) {
          return cache.config.dataIdFromObject(obj);
        } else {
          invariant(false, 6) ;
        }
      }
    });

    return newContext;
  };

  LocalState.prototype.addExportedVariables = function (document, variables, context) {
    if (variables === void 0) {
      variables = {};
    }

    if (context === void 0) {
      context = {};
    }

    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        if (document) {
          return [2, this.resolveDocument(document, this.buildRootValueFromCache(document, variables) || {}, this.prepareContext(context), variables).then(function (data) {
            return __assign(__assign({}, variables), data.exportedVariables);
          })];
        }

        return [2, __assign({}, variables)];
      });
    });
  };

  LocalState.prototype.shouldForceResolvers = function (document) {
    var forceResolvers = false;
    visit(document, {
      Directive: {
        enter: function (node) {
          if (node.name.value === 'client' && node.arguments) {
            forceResolvers = node.arguments.some(function (arg) {
              return arg.name.value === 'always' && arg.value.kind === 'BooleanValue' && arg.value.value === true;
            });

            if (forceResolvers) {
              return BREAK;
            }
          }
        }
      }
    });
    return forceResolvers;
  };

  LocalState.prototype.buildRootValueFromCache = function (document, variables) {
    return this.cache.diff({
      query: buildQueryFromSelectionSet(document),
      variables: variables,
      returnPartialData: true,
      optimistic: false
    }).result;
  };

  LocalState.prototype.resolveDocument = function (document, rootValue, context, variables, fragmentMatcher, onlyRunForcedResolvers) {
    if (context === void 0) {
      context = {};
    }

    if (variables === void 0) {
      variables = {};
    }

    if (fragmentMatcher === void 0) {
      fragmentMatcher = function () {
        return true;
      };
    }

    if (onlyRunForcedResolvers === void 0) {
      onlyRunForcedResolvers = false;
    }

    return __awaiter(this, void 0, void 0, function () {
      var mainDefinition, fragments, fragmentMap, definitionOperation, defaultOperationType, _a, cache, client, execContext;

      return __generator(this, function (_b) {
        mainDefinition = getMainDefinition(document);
        fragments = getFragmentDefinitions(document);
        fragmentMap = createFragmentMap(fragments);
        definitionOperation = mainDefinition.operation;
        defaultOperationType = definitionOperation ? capitalizeFirstLetter(definitionOperation) : 'Query';
        _a = this, cache = _a.cache, client = _a.client;
        execContext = {
          fragmentMap: fragmentMap,
          context: __assign(__assign({}, context), {
            cache: cache,
            client: client
          }),
          variables: variables,
          fragmentMatcher: fragmentMatcher,
          defaultOperationType: defaultOperationType,
          exportedVariables: {},
          onlyRunForcedResolvers: onlyRunForcedResolvers
        };
        return [2, this.resolveSelectionSet(mainDefinition.selectionSet, rootValue, execContext).then(function (result) {
          return {
            result: result,
            exportedVariables: execContext.exportedVariables
          };
        })];
      });
    });
  };

  LocalState.prototype.resolveSelectionSet = function (selectionSet, rootValue, execContext) {
    return __awaiter(this, void 0, void 0, function () {
      var fragmentMap, context, variables, resultsToMerge, execute;

      var _this = this;

      return __generator(this, function (_a) {
        fragmentMap = execContext.fragmentMap, context = execContext.context, variables = execContext.variables;
        resultsToMerge = [rootValue];

        execute = function (selection) {
          return __awaiter(_this, void 0, void 0, function () {
            var fragment, typeCondition;
            return __generator(this, function (_a) {
              if (!shouldInclude(selection, variables)) {
                return [2];
              }

              if (isField(selection)) {
                return [2, this.resolveField(selection, rootValue, execContext).then(function (fieldResult) {
                  var _a;

                  if (typeof fieldResult !== 'undefined') {
                    resultsToMerge.push((_a = {}, _a[resultKeyNameFromField(selection)] = fieldResult, _a));
                  }
                })];
              }

              if (isInlineFragment(selection)) {
                fragment = selection;
              } else {
                fragment = fragmentMap[selection.name.value];
                invariant(fragment, 7) ;
              }

              if (fragment && fragment.typeCondition) {
                typeCondition = fragment.typeCondition.name.value;

                if (execContext.fragmentMatcher(rootValue, typeCondition, context)) {
                  return [2, this.resolveSelectionSet(fragment.selectionSet, rootValue, execContext).then(function (fragmentResult) {
                    resultsToMerge.push(fragmentResult);
                  })];
                }
              }

              return [2];
            });
          });
        };

        return [2, Promise.all(selectionSet.selections.map(execute)).then(function () {
          return mergeDeepArray(resultsToMerge);
        })];
      });
    });
  };

  LocalState.prototype.resolveField = function (field, rootValue, execContext) {
    return __awaiter(this, void 0, void 0, function () {
      var variables, fieldName, aliasedFieldName, aliasUsed, defaultResult, resultPromise, resolverType, resolverMap, resolve;

      var _this = this;

      return __generator(this, function (_a) {
        variables = execContext.variables;
        fieldName = field.name.value;
        aliasedFieldName = resultKeyNameFromField(field);
        aliasUsed = fieldName !== aliasedFieldName;
        defaultResult = rootValue[aliasedFieldName] || rootValue[fieldName];
        resultPromise = Promise.resolve(defaultResult);

        if (!execContext.onlyRunForcedResolvers || this.shouldForceResolvers(field)) {
          resolverType = rootValue.__typename || execContext.defaultOperationType;
          resolverMap = this.resolvers && this.resolvers[resolverType];

          if (resolverMap) {
            resolve = resolverMap[aliasUsed ? fieldName : aliasedFieldName];

            if (resolve) {
              resultPromise = Promise.resolve(resolve(rootValue, argumentsObjectFromField(field, variables), execContext.context, {
                field: field,
                fragmentMap: execContext.fragmentMap
              }));
            }
          }
        }

        return [2, resultPromise.then(function (result) {
          if (result === void 0) {
            result = defaultResult;
          }

          if (field.directives) {
            field.directives.forEach(function (directive) {
              if (directive.name.value === 'export' && directive.arguments) {
                directive.arguments.forEach(function (arg) {
                  if (arg.name.value === 'as' && arg.value.kind === 'StringValue') {
                    execContext.exportedVariables[arg.value.value] = result;
                  }
                });
              }
            });
          }

          if (!field.selectionSet) {
            return result;
          }

          if (result == null) {
            return result;
          }

          if (Array.isArray(result)) {
            return _this.resolveSubSelectedArray(field, result, execContext);
          }

          if (field.selectionSet) {
            return _this.resolveSelectionSet(field.selectionSet, result, execContext);
          }
        })];
      });
    });
  };

  LocalState.prototype.resolveSubSelectedArray = function (field, result, execContext) {
    var _this = this;

    return Promise.all(result.map(function (item) {
      if (item === null) {
        return null;
      }

      if (Array.isArray(item)) {
        return _this.resolveSubSelectedArray(field, item, execContext);
      }

      if (field.selectionSet) {
        return _this.resolveSelectionSet(field.selectionSet, item, execContext);
      }
    }));
  };

  return LocalState;
}();

function multiplex(inner) {
  var observers = new Set();
  var sub = null;
  return new Observable(function (observer) {
    observers.add(observer);
    sub = sub || inner.subscribe({
      next: function (value) {
        observers.forEach(function (obs) {
          return obs.next && obs.next(value);
        });
      },
      error: function (error) {
        observers.forEach(function (obs) {
          return obs.error && obs.error(error);
        });
      },
      complete: function () {
        observers.forEach(function (obs) {
          return obs.complete && obs.complete();
        });
      }
    });
    return function () {
      if (observers.delete(observer) && !observers.size && sub) {
        sub.unsubscribe();
        sub = null;
      }
    };
  });
}

function asyncMap(observable, mapFn) {
  return new Observable(function (observer) {
    var next = observer.next,
        error = observer.error,
        complete = observer.complete;
    var activeNextCount = 0;
    var completed = false;
    var handler = {
      next: function (value) {
        ++activeNextCount;
        new Promise(function (resolve) {
          resolve(mapFn(value));
        }).then(function (result) {
          --activeNextCount;
          next && next.call(observer, result);
          completed && handler.complete();
        }, function (e) {
          --activeNextCount;
          error && error.call(observer, e);
        });
      },
      error: function (e) {
        error && error.call(observer, e);
      },
      complete: function () {
        completed = true;

        if (!activeNextCount) {
          complete && complete.call(observer);
        }
      }
    };
    var sub = observable.subscribe(handler);
    return function () {
      return sub.unsubscribe();
    };
  });
}

var hasOwnProperty = Object.prototype.hasOwnProperty;

var QueryManager = function () {
  function QueryManager(_a) {
    var link = _a.link,
        _b = _a.queryDeduplication,
        queryDeduplication = _b === void 0 ? false : _b,
        store = _a.store,
        _c = _a.onBroadcast,
        onBroadcast = _c === void 0 ? function () {
      return undefined;
    } : _c,
        _d = _a.ssrMode,
        ssrMode = _d === void 0 ? false : _d,
        _e = _a.clientAwareness,
        clientAwareness = _e === void 0 ? {} : _e,
        localState = _a.localState,
        assumeImmutableResults = _a.assumeImmutableResults;
    this.mutationStore = new MutationStore();
    this.queryStore = new QueryStore();
    this.clientAwareness = {};
    this.idCounter = 1;
    this.queries = new Map();
    this.fetchQueryRejectFns = new Map();
    this.transformCache = new (canUseWeakMap ? WeakMap : Map)();
    this.inFlightLinkObservables = new Map();
    this.pollingInfoByQueryId = new Map();
    this.link = link;
    this.queryDeduplication = queryDeduplication;
    this.dataStore = store;
    this.onBroadcast = onBroadcast;
    this.clientAwareness = clientAwareness;
    this.localState = localState || new LocalState({
      cache: store.getCache()
    });
    this.ssrMode = ssrMode;
    this.assumeImmutableResults = !!assumeImmutableResults;
  }

  QueryManager.prototype.stop = function () {
    var _this = this;

    this.queries.forEach(function (_info, queryId) {
      _this.stopQueryNoBroadcast(queryId);
    });
    this.fetchQueryRejectFns.forEach(function (reject) {
      reject(new InvariantError(8) );
    });
  };

  QueryManager.prototype.mutate = function (_a) {
    var mutation = _a.mutation,
        variables = _a.variables,
        optimisticResponse = _a.optimisticResponse,
        updateQueriesByName = _a.updateQueries,
        _b = _a.refetchQueries,
        refetchQueries = _b === void 0 ? [] : _b,
        _c = _a.awaitRefetchQueries,
        awaitRefetchQueries = _c === void 0 ? false : _c,
        updateWithProxyFn = _a.update,
        _d = _a.errorPolicy,
        errorPolicy = _d === void 0 ? 'none' : _d,
        fetchPolicy = _a.fetchPolicy,
        _e = _a.context,
        context = _e === void 0 ? {} : _e;
    return __awaiter(this, void 0, void 0, function () {
      var mutationId, generateUpdateQueriesInfo, self;

      var _this = this;

      return __generator(this, function (_f) {
        switch (_f.label) {
          case 0:
            invariant(mutation, 9) ;
            invariant(!fetchPolicy || fetchPolicy === 'no-cache', 10) ;
            mutationId = this.generateQueryId();
            mutation = this.transform(mutation).document;
            this.setQuery(mutationId, function () {
              return {
                document: mutation
              };
            });
            variables = this.getVariables(mutation, variables);
            if (!this.transform(mutation).hasClientExports) return [3, 2];
            return [4, this.localState.addExportedVariables(mutation, variables, context)];

          case 1:
            variables = _f.sent();
            _f.label = 2;

          case 2:
            generateUpdateQueriesInfo = function () {
              var ret = {};

              if (updateQueriesByName) {
                _this.queries.forEach(function (_a, queryId) {
                  var observableQuery = _a.observableQuery;

                  if (observableQuery) {
                    var queryName = observableQuery.queryName;

                    if (queryName && hasOwnProperty.call(updateQueriesByName, queryName)) {
                      ret[queryId] = {
                        updater: updateQueriesByName[queryName],
                        query: _this.queryStore.get(queryId)
                      };
                    }
                  }
                });
              }

              return ret;
            };

            this.mutationStore.initMutation(mutationId, mutation, variables);
            this.dataStore.markMutationInit({
              mutationId: mutationId,
              document: mutation,
              variables: variables,
              updateQueries: generateUpdateQueriesInfo(),
              update: updateWithProxyFn,
              optimisticResponse: optimisticResponse
            });
            this.broadcastQueries();
            self = this;
            return [2, new Promise(function (resolve, reject) {
              var storeResult;
              var error;
              self.getObservableFromLink(mutation, __assign(__assign({}, context), {
                optimisticResponse: optimisticResponse
              }), variables, false).subscribe({
                next: function (result) {
                  if (graphQLResultHasError(result) && errorPolicy === 'none') {
                    error = new ApolloError({
                      graphQLErrors: result.errors
                    });
                    return;
                  }

                  self.mutationStore.markMutationResult(mutationId);

                  if (fetchPolicy !== 'no-cache') {
                    self.dataStore.markMutationResult({
                      mutationId: mutationId,
                      result: result,
                      document: mutation,
                      variables: variables,
                      updateQueries: generateUpdateQueriesInfo(),
                      update: updateWithProxyFn
                    });
                  }

                  storeResult = result;
                },
                error: function (err) {
                  self.mutationStore.markMutationError(mutationId, err);
                  self.dataStore.markMutationComplete({
                    mutationId: mutationId,
                    optimisticResponse: optimisticResponse
                  });
                  self.broadcastQueries();
                  self.setQuery(mutationId, function () {
                    return {
                      document: null
                    };
                  });
                  reject(new ApolloError({
                    networkError: err
                  }));
                },
                complete: function () {
                  if (error) {
                    self.mutationStore.markMutationError(mutationId, error);
                  }

                  self.dataStore.markMutationComplete({
                    mutationId: mutationId,
                    optimisticResponse: optimisticResponse
                  });
                  self.broadcastQueries();

                  if (error) {
                    reject(error);
                    return;
                  }

                  if (typeof refetchQueries === 'function') {
                    refetchQueries = refetchQueries(storeResult);
                  }

                  var refetchQueryPromises = [];

                  if (isNonEmptyArray(refetchQueries)) {
                    refetchQueries.forEach(function (refetchQuery) {
                      if (typeof refetchQuery === 'string') {
                        self.queries.forEach(function (_a) {
                          var observableQuery = _a.observableQuery;

                          if (observableQuery && observableQuery.queryName === refetchQuery) {
                            refetchQueryPromises.push(observableQuery.refetch());
                          }
                        });
                      } else {
                        var queryOptions = {
                          query: refetchQuery.query,
                          variables: refetchQuery.variables,
                          fetchPolicy: 'network-only'
                        };

                        if (refetchQuery.context) {
                          queryOptions.context = refetchQuery.context;
                        }

                        refetchQueryPromises.push(self.query(queryOptions));
                      }
                    });
                  }

                  Promise.all(awaitRefetchQueries ? refetchQueryPromises : []).then(function () {
                    self.setQuery(mutationId, function () {
                      return {
                        document: null
                      };
                    });

                    if (errorPolicy === 'ignore' && storeResult && graphQLResultHasError(storeResult)) {
                      delete storeResult.errors;
                    }

                    resolve(storeResult);
                  });
                }
              });
            })];
        }
      });
    });
  };

  QueryManager.prototype.fetchQuery = function (queryId, options, fetchType, fetchMoreForQueryId) {
    return __awaiter(this, void 0, void 0, function () {
      var _a, metadata, _b, fetchPolicy, _c, context, query, variables, storeResult, isNetworkOnly, needToFetch, _d, complete, result, shouldFetch, requestId, cancel, networkResult;

      var _this = this;

      return __generator(this, function (_e) {
        switch (_e.label) {
          case 0:
            _a = options.metadata, metadata = _a === void 0 ? null : _a, _b = options.fetchPolicy, fetchPolicy = _b === void 0 ? 'cache-first' : _b, _c = options.context, context = _c === void 0 ? {} : _c;
            query = this.transform(options.query).document;
            variables = this.getVariables(query, options.variables);
            if (!this.transform(query).hasClientExports) return [3, 2];
            return [4, this.localState.addExportedVariables(query, variables, context)];

          case 1:
            variables = _e.sent();
            _e.label = 2;

          case 2:
            options = __assign(__assign({}, options), {
              variables: variables
            });
            isNetworkOnly = fetchPolicy === 'network-only' || fetchPolicy === 'no-cache';
            needToFetch = isNetworkOnly;

            if (!isNetworkOnly) {
              _d = this.dataStore.getCache().diff({
                query: query,
                variables: variables,
                returnPartialData: true,
                optimistic: false
              }), complete = _d.complete, result = _d.result;
              needToFetch = !complete || fetchPolicy === 'cache-and-network';
              storeResult = result;
            }

            shouldFetch = needToFetch && fetchPolicy !== 'cache-only' && fetchPolicy !== 'standby';
            if (hasDirectives(['live'], query)) shouldFetch = true;
            requestId = this.idCounter++;
            cancel = fetchPolicy !== 'no-cache' ? this.updateQueryWatch(queryId, query, options) : undefined;
            this.setQuery(queryId, function () {
              return {
                document: query,
                lastRequestId: requestId,
                invalidated: true,
                cancel: cancel
              };
            });
            this.invalidate(fetchMoreForQueryId);
            this.queryStore.initQuery({
              queryId: queryId,
              document: query,
              storePreviousVariables: shouldFetch,
              variables: variables,
              isPoll: fetchType === FetchType.poll,
              isRefetch: fetchType === FetchType.refetch,
              metadata: metadata,
              fetchMoreForQueryId: fetchMoreForQueryId
            });
            this.broadcastQueries();

            if (shouldFetch) {
              networkResult = this.fetchRequest({
                requestId: requestId,
                queryId: queryId,
                document: query,
                options: options,
                fetchMoreForQueryId: fetchMoreForQueryId
              }).catch(function (error) {
                if (isApolloError(error)) {
                  throw error;
                } else {
                  if (requestId >= _this.getQuery(queryId).lastRequestId) {
                    _this.queryStore.markQueryError(queryId, error, fetchMoreForQueryId);

                    _this.invalidate(queryId);

                    _this.invalidate(fetchMoreForQueryId);

                    _this.broadcastQueries();
                  }

                  throw new ApolloError({
                    networkError: error
                  });
                }
              });

              if (fetchPolicy !== 'cache-and-network') {
                return [2, networkResult];
              }

              networkResult.catch(function () {});
            }

            this.queryStore.markQueryResultClient(queryId, !shouldFetch);
            this.invalidate(queryId);
            this.invalidate(fetchMoreForQueryId);

            if (this.transform(query).hasForcedResolvers) {
              return [2, this.localState.runResolvers({
                document: query,
                remoteResult: {
                  data: storeResult
                },
                context: context,
                variables: variables,
                onlyRunForcedResolvers: true
              }).then(function (result) {
                _this.markQueryResult(queryId, result, options, fetchMoreForQueryId);

                _this.broadcastQueries();

                return result;
              })];
            }

            this.broadcastQueries();
            return [2, {
              data: storeResult
            }];
        }
      });
    });
  };

  QueryManager.prototype.markQueryResult = function (queryId, result, _a, fetchMoreForQueryId) {
    var fetchPolicy = _a.fetchPolicy,
        variables = _a.variables,
        errorPolicy = _a.errorPolicy;

    if (fetchPolicy === 'no-cache') {
      this.setQuery(queryId, function () {
        return {
          newData: {
            result: result.data,
            complete: true
          }
        };
      });
    } else {
      this.dataStore.markQueryResult(result, this.getQuery(queryId).document, variables, fetchMoreForQueryId, errorPolicy === 'ignore' || errorPolicy === 'all');
    }
  };

  QueryManager.prototype.queryListenerForObserver = function (queryId, options, observer) {
    var _this = this;

    function invoke(method, argument) {
      if (observer[method]) {
        try {
          observer[method](argument);
        } catch (e) {
        }
      }
    }

    return function (queryStoreValue, newData) {
      _this.invalidate(queryId, false);

      if (!queryStoreValue) return;

      var _a = _this.getQuery(queryId),
          observableQuery = _a.observableQuery,
          document = _a.document;

      var fetchPolicy = observableQuery ? observableQuery.options.fetchPolicy : options.fetchPolicy;
      if (fetchPolicy === 'standby') return;
      var loading = isNetworkRequestInFlight(queryStoreValue.networkStatus);
      var lastResult = observableQuery && observableQuery.getLastResult();
      var networkStatusChanged = !!(lastResult && lastResult.networkStatus !== queryStoreValue.networkStatus);
      var shouldNotifyIfLoading = options.returnPartialData || !newData && queryStoreValue.previousVariables || networkStatusChanged && options.notifyOnNetworkStatusChange || fetchPolicy === 'cache-only' || fetchPolicy === 'cache-and-network';

      if (loading && !shouldNotifyIfLoading) {
        return;
      }

      var hasGraphQLErrors = isNonEmptyArray(queryStoreValue.graphQLErrors);
      var errorPolicy = observableQuery && observableQuery.options.errorPolicy || options.errorPolicy || 'none';

      if (errorPolicy === 'none' && hasGraphQLErrors || queryStoreValue.networkError) {
        return invoke('error', new ApolloError({
          graphQLErrors: queryStoreValue.graphQLErrors,
          networkError: queryStoreValue.networkError
        }));
      }

      try {
        var data = void 0;
        var isMissing = void 0;

        if (newData) {
          if (fetchPolicy !== 'no-cache' && fetchPolicy !== 'network-only') {
            _this.setQuery(queryId, function () {
              return {
                newData: null
              };
            });
          }

          data = newData.result;
          isMissing = !newData.complete;
        } else {
          var lastError = observableQuery && observableQuery.getLastError();
          var errorStatusChanged = errorPolicy !== 'none' && (lastError && lastError.graphQLErrors) !== queryStoreValue.graphQLErrors;

          if (lastResult && lastResult.data && !errorStatusChanged) {
            data = lastResult.data;
            isMissing = false;
          } else {
            var diffResult = _this.dataStore.getCache().diff({
              query: document,
              variables: queryStoreValue.previousVariables || queryStoreValue.variables,
              returnPartialData: true,
              optimistic: true
            });

            data = diffResult.result;
            isMissing = !diffResult.complete;
          }
        }

        var stale = isMissing && !(options.returnPartialData || fetchPolicy === 'cache-only');
        var resultFromStore = {
          data: stale ? lastResult && lastResult.data : data,
          loading: loading,
          networkStatus: queryStoreValue.networkStatus,
          stale: stale
        };

        if (errorPolicy === 'all' && hasGraphQLErrors) {
          resultFromStore.errors = queryStoreValue.graphQLErrors;
        }

        invoke('next', resultFromStore);
      } catch (networkError) {
        invoke('error', new ApolloError({
          networkError: networkError
        }));
      }
    };
  };

  QueryManager.prototype.transform = function (document) {
    var transformCache = this.transformCache;

    if (!transformCache.has(document)) {
      var cache = this.dataStore.getCache();
      var transformed = cache.transformDocument(document);
      var forLink = removeConnectionDirectiveFromDocument(cache.transformForLink(transformed));
      var clientQuery = this.localState.clientQuery(transformed);
      var serverQuery = this.localState.serverQuery(forLink);
      var cacheEntry_1 = {
        document: transformed,
        hasClientExports: hasClientExports(transformed),
        hasForcedResolvers: this.localState.shouldForceResolvers(transformed),
        clientQuery: clientQuery,
        serverQuery: serverQuery,
        defaultVars: getDefaultValues(getOperationDefinition(transformed))
      };

      var add = function (doc) {
        if (doc && !transformCache.has(doc)) {
          transformCache.set(doc, cacheEntry_1);
        }
      };

      add(document);
      add(transformed);
      add(clientQuery);
      add(serverQuery);
    }

    return transformCache.get(document);
  };

  QueryManager.prototype.getVariables = function (document, variables) {
    return __assign(__assign({}, this.transform(document).defaultVars), variables);
  };

  QueryManager.prototype.watchQuery = function (options, shouldSubscribe) {
    if (shouldSubscribe === void 0) {
      shouldSubscribe = true;
    }

    invariant(options.fetchPolicy !== 'standby', 11) ;
    options.variables = this.getVariables(options.query, options.variables);

    if (typeof options.notifyOnNetworkStatusChange === 'undefined') {
      options.notifyOnNetworkStatusChange = false;
    }

    var transformedOptions = __assign({}, options);

    return new ObservableQuery({
      queryManager: this,
      options: transformedOptions,
      shouldSubscribe: shouldSubscribe
    });
  };

  QueryManager.prototype.query = function (options) {
    var _this = this;

    invariant(options.query, 12) ;
    invariant(options.query.kind === 'Document', 13) ;
    invariant(!options.returnPartialData, 14) ;
    invariant(!options.pollInterval, 15) ;
    return new Promise(function (resolve, reject) {
      var watchedQuery = _this.watchQuery(options, false);

      _this.fetchQueryRejectFns.set("query:" + watchedQuery.queryId, reject);

      watchedQuery.result().then(resolve, reject).then(function () {
        return _this.fetchQueryRejectFns.delete("query:" + watchedQuery.queryId);
      });
    });
  };

  QueryManager.prototype.generateQueryId = function () {
    return String(this.idCounter++);
  };

  QueryManager.prototype.stopQueryInStore = function (queryId) {
    this.stopQueryInStoreNoBroadcast(queryId);
    this.broadcastQueries();
  };

  QueryManager.prototype.stopQueryInStoreNoBroadcast = function (queryId) {
    this.stopPollingQuery(queryId);
    this.queryStore.stopQuery(queryId);
    this.invalidate(queryId);
  };

  QueryManager.prototype.addQueryListener = function (queryId, listener) {
    this.setQuery(queryId, function (_a) {
      var listeners = _a.listeners;
      listeners.add(listener);
      return {
        invalidated: false
      };
    });
  };

  QueryManager.prototype.updateQueryWatch = function (queryId, document, options) {
    var _this = this;

    var cancel = this.getQuery(queryId).cancel;
    if (cancel) cancel();

    var previousResult = function () {
      var previousResult = null;

      var observableQuery = _this.getQuery(queryId).observableQuery;

      if (observableQuery) {
        var lastResult = observableQuery.getLastResult();

        if (lastResult) {
          previousResult = lastResult.data;
        }
      }

      return previousResult;
    };

    return this.dataStore.getCache().watch({
      query: document,
      variables: options.variables,
      optimistic: true,
      previousResult: previousResult,
      callback: function (newData) {
        _this.setQuery(queryId, function () {
          return {
            invalidated: true,
            newData: newData
          };
        });
      }
    });
  };

  QueryManager.prototype.addObservableQuery = function (queryId, observableQuery) {
    this.setQuery(queryId, function () {
      return {
        observableQuery: observableQuery
      };
    });
  };

  QueryManager.prototype.removeObservableQuery = function (queryId) {
    var cancel = this.getQuery(queryId).cancel;
    this.setQuery(queryId, function () {
      return {
        observableQuery: null
      };
    });
    if (cancel) cancel();
  };

  QueryManager.prototype.clearStore = function () {
    this.fetchQueryRejectFns.forEach(function (reject) {
      reject(new InvariantError(16) );
    });
    var resetIds = [];
    this.queries.forEach(function (_a, queryId) {
      var observableQuery = _a.observableQuery;
      if (observableQuery) resetIds.push(queryId);
    });
    this.queryStore.reset(resetIds);
    this.mutationStore.reset();
    return this.dataStore.reset();
  };

  QueryManager.prototype.resetStore = function () {
    var _this = this;

    return this.clearStore().then(function () {
      return _this.reFetchObservableQueries();
    });
  };

  QueryManager.prototype.reFetchObservableQueries = function (includeStandby) {
    var _this = this;

    if (includeStandby === void 0) {
      includeStandby = false;
    }

    var observableQueryPromises = [];
    this.queries.forEach(function (_a, queryId) {
      var observableQuery = _a.observableQuery;

      if (observableQuery) {
        var fetchPolicy = observableQuery.options.fetchPolicy;
        observableQuery.resetLastResults();

        if (fetchPolicy !== 'cache-only' && (includeStandby || fetchPolicy !== 'standby')) {
          observableQueryPromises.push(observableQuery.refetch());
        }

        _this.setQuery(queryId, function () {
          return {
            newData: null
          };
        });

        _this.invalidate(queryId);
      }
    });
    this.broadcastQueries();
    return Promise.all(observableQueryPromises);
  };

  QueryManager.prototype.observeQuery = function (queryId, options, observer) {
    this.addQueryListener(queryId, this.queryListenerForObserver(queryId, options, observer));
    return this.fetchQuery(queryId, options);
  };

  QueryManager.prototype.startQuery = function (queryId, options, listener) {
    this.addQueryListener(queryId, listener);
    this.fetchQuery(queryId, options).catch(function () {
      return undefined;
    });
    return queryId;
  };

  QueryManager.prototype.startGraphQLSubscription = function (_a) {
    var _this = this;

    var query = _a.query,
        fetchPolicy = _a.fetchPolicy,
        variables = _a.variables;
    query = this.transform(query).document;
    variables = this.getVariables(query, variables);

    var makeObservable = function (variables) {
      return _this.getObservableFromLink(query, {}, variables, false).map(function (result) {
        if (!fetchPolicy || fetchPolicy !== 'no-cache') {
          _this.dataStore.markSubscriptionResult(result, query, variables);

          _this.broadcastQueries();
        }

        if (graphQLResultHasError(result)) {
          throw new ApolloError({
            graphQLErrors: result.errors
          });
        }

        return result;
      });
    };

    if (this.transform(query).hasClientExports) {
      var observablePromise_1 = this.localState.addExportedVariables(query, variables).then(makeObservable);
      return new Observable(function (observer) {
        var sub = null;
        observablePromise_1.then(function (observable) {
          return sub = observable.subscribe(observer);
        }, observer.error);
        return function () {
          return sub && sub.unsubscribe();
        };
      });
    }

    return makeObservable(variables);
  };

  QueryManager.prototype.stopQuery = function (queryId) {
    this.stopQueryNoBroadcast(queryId);
    this.broadcastQueries();
  };

  QueryManager.prototype.stopQueryNoBroadcast = function (queryId) {
    this.stopQueryInStoreNoBroadcast(queryId);
    this.removeQuery(queryId);
  };

  QueryManager.prototype.removeQuery = function (queryId) {
    this.fetchQueryRejectFns.delete("query:" + queryId);
    this.fetchQueryRejectFns.delete("fetchRequest:" + queryId);
    this.getQuery(queryId).subscriptions.forEach(function (x) {
      return x.unsubscribe();
    });
    this.queries.delete(queryId);
  };

  QueryManager.prototype.getCurrentQueryResult = function (observableQuery, optimistic) {
    if (optimistic === void 0) {
      optimistic = true;
    }

    var _a = observableQuery.options,
        variables = _a.variables,
        query = _a.query,
        fetchPolicy = _a.fetchPolicy,
        returnPartialData = _a.returnPartialData;
    var lastResult = observableQuery.getLastResult();
    var newData = this.getQuery(observableQuery.queryId).newData;

    if (newData && newData.complete) {
      return {
        data: newData.result,
        partial: false
      };
    }

    if (fetchPolicy === 'no-cache' || fetchPolicy === 'network-only') {
      return {
        data: undefined,
        partial: false
      };
    }

    var _b = this.dataStore.getCache().diff({
      query: query,
      variables: variables,
      previousResult: lastResult ? lastResult.data : undefined,
      returnPartialData: true,
      optimistic: optimistic
    }),
        result = _b.result,
        complete = _b.complete;

    return {
      data: complete || returnPartialData ? result : void 0,
      partial: !complete
    };
  };

  QueryManager.prototype.getQueryWithPreviousResult = function (queryIdOrObservable) {
    var observableQuery;

    if (typeof queryIdOrObservable === 'string') {
      var foundObserveableQuery = this.getQuery(queryIdOrObservable).observableQuery;
      invariant(foundObserveableQuery, 17) ;
      observableQuery = foundObserveableQuery;
    } else {
      observableQuery = queryIdOrObservable;
    }

    var _a = observableQuery.options,
        variables = _a.variables,
        query = _a.query;
    return {
      previousResult: this.getCurrentQueryResult(observableQuery, false).data,
      variables: variables,
      document: query
    };
  };

  QueryManager.prototype.broadcastQueries = function () {
    var _this = this;

    this.onBroadcast();
    this.queries.forEach(function (info, id) {
      if (info.invalidated) {
        info.listeners.forEach(function (listener) {
          if (listener) {
            listener(_this.queryStore.get(id), info.newData);
          }
        });
      }
    });
  };

  QueryManager.prototype.getLocalState = function () {
    return this.localState;
  };

  QueryManager.prototype.getObservableFromLink = function (query, context, variables, deduplication) {
    var _this = this;

    if (deduplication === void 0) {
      deduplication = this.queryDeduplication;
    }

    var observable;
    var serverQuery = this.transform(query).serverQuery;

    if (serverQuery) {
      var _a = this,
          inFlightLinkObservables_1 = _a.inFlightLinkObservables,
          link = _a.link;

      var operation = {
        query: serverQuery,
        variables: variables,
        operationName: getOperationName(serverQuery) || void 0,
        context: this.prepareContext(__assign(__assign({}, context), {
          forceFetch: !deduplication
        }))
      };
      context = operation.context;

      if (deduplication) {
        var byVariables_1 = inFlightLinkObservables_1.get(serverQuery) || new Map();
        inFlightLinkObservables_1.set(serverQuery, byVariables_1);
        var varJson_1 = JSON.stringify(variables);
        observable = byVariables_1.get(varJson_1);

        if (!observable) {
          byVariables_1.set(varJson_1, observable = multiplex(execute(link, operation)));

          var cleanup = function () {
            byVariables_1.delete(varJson_1);
            if (!byVariables_1.size) inFlightLinkObservables_1.delete(serverQuery);
            cleanupSub_1.unsubscribe();
          };

          var cleanupSub_1 = observable.subscribe({
            next: cleanup,
            error: cleanup,
            complete: cleanup
          });
        }
      } else {
        observable = multiplex(execute(link, operation));
      }
    } else {
      observable = Observable.of({
        data: {}
      });
      context = this.prepareContext(context);
    }

    var clientQuery = this.transform(query).clientQuery;

    if (clientQuery) {
      observable = asyncMap(observable, function (result) {
        return _this.localState.runResolvers({
          document: clientQuery,
          remoteResult: result,
          context: context,
          variables: variables
        });
      });
    }

    return observable;
  };

  QueryManager.prototype.fetchRequest = function (_a) {
    var _this = this;

    var requestId = _a.requestId,
        queryId = _a.queryId,
        document = _a.document,
        options = _a.options,
        fetchMoreForQueryId = _a.fetchMoreForQueryId;
    var variables = options.variables,
        _b = options.errorPolicy,
        errorPolicy = _b === void 0 ? 'none' : _b,
        fetchPolicy = options.fetchPolicy;
    var resultFromStore;
    var errorsFromStore;
    return new Promise(function (resolve, reject) {
      var observable = _this.getObservableFromLink(document, options.context, variables);

      var fqrfId = "fetchRequest:" + queryId;

      _this.fetchQueryRejectFns.set(fqrfId, reject);

      var cleanup = function () {
        _this.fetchQueryRejectFns.delete(fqrfId);

        _this.setQuery(queryId, function (_a) {
          var subscriptions = _a.subscriptions;
          subscriptions.delete(subscription);
        });
      };

      var subscription = observable.map(function (result) {
        if (requestId >= _this.getQuery(queryId).lastRequestId) {
          _this.markQueryResult(queryId, result, options, fetchMoreForQueryId);

          _this.queryStore.markQueryResult(queryId, result, fetchMoreForQueryId);

          _this.invalidate(queryId);

          _this.invalidate(fetchMoreForQueryId);

          _this.broadcastQueries();
        }

        if (errorPolicy === 'none' && isNonEmptyArray(result.errors)) {
          return reject(new ApolloError({
            graphQLErrors: result.errors
          }));
        }

        if (errorPolicy === 'all') {
          errorsFromStore = result.errors;
        }

        if (fetchMoreForQueryId || fetchPolicy === 'no-cache') {
          resultFromStore = result.data;
        } else {
          var _a = _this.dataStore.getCache().diff({
            variables: variables,
            query: document,
            optimistic: false,
            returnPartialData: true
          }),
              result_1 = _a.result,
              complete = _a.complete;

          if (complete || options.returnPartialData) {
            resultFromStore = result_1;
          }
        }
      }).subscribe({
        error: function (error) {
          cleanup();
          reject(error);
        },
        complete: function () {
          cleanup();
          resolve({
            data: resultFromStore,
            errors: errorsFromStore,
            loading: false,
            networkStatus: NetworkStatus.ready,
            stale: false
          });
        }
      });

      _this.setQuery(queryId, function (_a) {
        var subscriptions = _a.subscriptions;
        subscriptions.add(subscription);
      });
    });
  };

  QueryManager.prototype.getQuery = function (queryId) {
    return this.queries.get(queryId) || {
      listeners: new Set(),
      invalidated: false,
      document: null,
      newData: null,
      lastRequestId: 1,
      observableQuery: null,
      subscriptions: new Set()
    };
  };

  QueryManager.prototype.setQuery = function (queryId, updater) {
    var prev = this.getQuery(queryId);

    var newInfo = __assign(__assign({}, prev), updater(prev));

    this.queries.set(queryId, newInfo);
  };

  QueryManager.prototype.invalidate = function (queryId, invalidated) {
    if (invalidated === void 0) {
      invalidated = true;
    }

    if (queryId) {
      this.setQuery(queryId, function () {
        return {
          invalidated: invalidated
        };
      });
    }
  };

  QueryManager.prototype.prepareContext = function (context) {
    if (context === void 0) {
      context = {};
    }

    var newContext = this.localState.prepareContext(context);
    return __assign(__assign({}, newContext), {
      clientAwareness: this.clientAwareness
    });
  };

  QueryManager.prototype.checkInFlight = function (queryId) {
    var query = this.queryStore.get(queryId);
    return query && query.networkStatus !== NetworkStatus.ready && query.networkStatus !== NetworkStatus.error;
  };

  QueryManager.prototype.startPollingQuery = function (options, queryId, listener) {
    var _this = this;

    var pollInterval = options.pollInterval;
    invariant(pollInterval, 18) ;

    if (!this.ssrMode) {
      var info = this.pollingInfoByQueryId.get(queryId);

      if (!info) {
        this.pollingInfoByQueryId.set(queryId, info = {});
      }

      info.interval = pollInterval;
      info.options = __assign(__assign({}, options), {
        fetchPolicy: 'network-only'
      });

      var maybeFetch_1 = function () {
        var info = _this.pollingInfoByQueryId.get(queryId);

        if (info) {
          if (_this.checkInFlight(queryId)) {
            poll_1();
          } else {
            _this.fetchQuery(queryId, info.options, FetchType.poll).then(poll_1, poll_1);
          }
        }
      };

      var poll_1 = function () {
        var info = _this.pollingInfoByQueryId.get(queryId);

        if (info) {
          clearTimeout(info.timeout);
          info.timeout = setTimeout(maybeFetch_1, info.interval);
        }
      };

      if (listener) {
        this.addQueryListener(queryId, listener);
      }

      poll_1();
    }

    return queryId;
  };

  QueryManager.prototype.stopPollingQuery = function (queryId) {
    this.pollingInfoByQueryId.delete(queryId);
  };

  return QueryManager;
}();

var DataStore = function () {
  function DataStore(initialCache) {
    this.cache = initialCache;
  }

  DataStore.prototype.getCache = function () {
    return this.cache;
  };

  DataStore.prototype.markQueryResult = function (result, document, variables, fetchMoreForQueryId, ignoreErrors) {
    if (ignoreErrors === void 0) {
      ignoreErrors = false;
    }

    var writeWithErrors = !graphQLResultHasError(result);

    if (ignoreErrors && graphQLResultHasError(result) && result.data) {
      writeWithErrors = true;
    }

    if (!fetchMoreForQueryId && writeWithErrors) {
      this.cache.write({
        result: result.data,
        dataId: 'ROOT_QUERY',
        query: document,
        variables: variables
      });
    }
  };

  DataStore.prototype.markSubscriptionResult = function (result, document, variables) {
    if (!graphQLResultHasError(result)) {
      this.cache.write({
        result: result.data,
        dataId: 'ROOT_SUBSCRIPTION',
        query: document,
        variables: variables
      });
    }
  };

  DataStore.prototype.markMutationInit = function (mutation) {
    var _this = this;

    if (mutation.optimisticResponse) {
      var optimistic_1;

      if (typeof mutation.optimisticResponse === 'function') {
        optimistic_1 = mutation.optimisticResponse(mutation.variables);
      } else {
        optimistic_1 = mutation.optimisticResponse;
      }

      this.cache.recordOptimisticTransaction(function (c) {
        var orig = _this.cache;
        _this.cache = c;

        try {
          _this.markMutationResult({
            mutationId: mutation.mutationId,
            result: {
              data: optimistic_1
            },
            document: mutation.document,
            variables: mutation.variables,
            updateQueries: mutation.updateQueries,
            update: mutation.update
          });
        } finally {
          _this.cache = orig;
        }
      }, mutation.mutationId);
    }
  };

  DataStore.prototype.markMutationResult = function (mutation) {
    var _this = this;

    if (!graphQLResultHasError(mutation.result)) {
      var cacheWrites_1 = [{
        result: mutation.result.data,
        dataId: 'ROOT_MUTATION',
        query: mutation.document,
        variables: mutation.variables
      }];
      var updateQueries_1 = mutation.updateQueries;

      if (updateQueries_1) {
        Object.keys(updateQueries_1).forEach(function (id) {
          var _a = updateQueries_1[id],
              query = _a.query,
              updater = _a.updater;

          var _b = _this.cache.diff({
            query: query.document,
            variables: query.variables,
            returnPartialData: true,
            optimistic: false
          }),
              currentQueryResult = _b.result,
              complete = _b.complete;

          if (complete) {
            var nextQueryResult = tryFunctionOrLogError(function () {
              return updater(currentQueryResult, {
                mutationResult: mutation.result,
                queryName: getOperationName(query.document) || undefined,
                queryVariables: query.variables
              });
            });

            if (nextQueryResult) {
              cacheWrites_1.push({
                result: nextQueryResult,
                dataId: 'ROOT_QUERY',
                query: query.document,
                variables: query.variables
              });
            }
          }
        });
      }

      this.cache.performTransaction(function (c) {
        cacheWrites_1.forEach(function (write) {
          return c.write(write);
        });
        var update = mutation.update;

        if (update) {
          tryFunctionOrLogError(function () {
            return update(c, mutation.result);
          });
        }
      });
    }
  };

  DataStore.prototype.markMutationComplete = function (_a) {
    var mutationId = _a.mutationId,
        optimisticResponse = _a.optimisticResponse;

    if (optimisticResponse) {
      this.cache.removeOptimistic(mutationId);
    }
  };

  DataStore.prototype.markUpdateQueryResult = function (document, variables, newResult) {
    this.cache.write({
      result: newResult,
      dataId: 'ROOT_QUERY',
      variables: variables,
      query: document
    });
  };

  DataStore.prototype.reset = function () {
    return this.cache.reset();
  };

  return DataStore;
}();

var version = "2.6.10";

var ApolloClient = function () {
  function ApolloClient(options) {
    var _this = this;

    this.defaultOptions = {};
    this.resetStoreCallbacks = [];
    this.clearStoreCallbacks = [];
    var cache = options.cache,
        _a = options.ssrMode,
        ssrMode = _a === void 0 ? false : _a,
        _b = options.ssrForceFetchDelay,
        ssrForceFetchDelay = _b === void 0 ? 0 : _b,
        connectToDevTools = options.connectToDevTools,
        _c = options.queryDeduplication,
        queryDeduplication = _c === void 0 ? true : _c,
        defaultOptions = options.defaultOptions,
        _d = options.assumeImmutableResults,
        assumeImmutableResults = _d === void 0 ? false : _d,
        resolvers = options.resolvers,
        typeDefs = options.typeDefs,
        fragmentMatcher = options.fragmentMatcher,
        clientAwarenessName = options.name,
        clientAwarenessVersion = options.version;
    var link = options.link;

    if (!link && resolvers) {
      link = ApolloLink.empty();
    }

    if (!link || !cache) {
      throw new InvariantError(4) ;
    }

    this.link = link;
    this.cache = cache;
    this.store = new DataStore(cache);
    this.disableNetworkFetches = ssrMode || ssrForceFetchDelay > 0;
    this.queryDeduplication = queryDeduplication;
    this.defaultOptions = defaultOptions || {};
    this.typeDefs = typeDefs;

    if (ssrForceFetchDelay) {
      setTimeout(function () {
        return _this.disableNetworkFetches = false;
      }, ssrForceFetchDelay);
    }

    this.watchQuery = this.watchQuery.bind(this);
    this.query = this.query.bind(this);
    this.mutate = this.mutate.bind(this);
    this.resetStore = this.resetStore.bind(this);
    this.reFetchObservableQueries = this.reFetchObservableQueries.bind(this);
    var defaultConnectToDevTools = "production" !== 'production'  ;

    if (typeof connectToDevTools === 'undefined' ? defaultConnectToDevTools : connectToDevTools && typeof window !== 'undefined') {
      window.__APOLLO_CLIENT__ = this;
    }

    this.version = version;
    this.localState = new LocalState({
      cache: cache,
      client: this,
      resolvers: resolvers,
      fragmentMatcher: fragmentMatcher
    });
    this.queryManager = new QueryManager({
      link: this.link,
      store: this.store,
      queryDeduplication: queryDeduplication,
      ssrMode: ssrMode,
      clientAwareness: {
        name: clientAwarenessName,
        version: clientAwarenessVersion
      },
      localState: this.localState,
      assumeImmutableResults: assumeImmutableResults,
      onBroadcast: function () {
        if (_this.devToolsHookCb) {
          _this.devToolsHookCb({
            action: {},
            state: {
              queries: _this.queryManager.queryStore.getStore(),
              mutations: _this.queryManager.mutationStore.getStore()
            },
            dataWithOptimisticResults: _this.cache.extract(true)
          });
        }
      }
    });
  }

  ApolloClient.prototype.stop = function () {
    this.queryManager.stop();
  };

  ApolloClient.prototype.watchQuery = function (options) {
    if (this.defaultOptions.watchQuery) {
      options = __assign(__assign({}, this.defaultOptions.watchQuery), options);
    }

    if (this.disableNetworkFetches && (options.fetchPolicy === 'network-only' || options.fetchPolicy === 'cache-and-network')) {
      options = __assign(__assign({}, options), {
        fetchPolicy: 'cache-first'
      });
    }

    return this.queryManager.watchQuery(options);
  };

  ApolloClient.prototype.query = function (options) {
    if (this.defaultOptions.query) {
      options = __assign(__assign({}, this.defaultOptions.query), options);
    }

    invariant(options.fetchPolicy !== 'cache-and-network', 5) ;

    if (this.disableNetworkFetches && options.fetchPolicy === 'network-only') {
      options = __assign(__assign({}, options), {
        fetchPolicy: 'cache-first'
      });
    }

    return this.queryManager.query(options);
  };

  ApolloClient.prototype.mutate = function (options) {
    if (this.defaultOptions.mutate) {
      options = __assign(__assign({}, this.defaultOptions.mutate), options);
    }

    return this.queryManager.mutate(options);
  };

  ApolloClient.prototype.subscribe = function (options) {
    return this.queryManager.startGraphQLSubscription(options);
  };

  ApolloClient.prototype.readQuery = function (options, optimistic) {
    if (optimistic === void 0) {
      optimistic = false;
    }

    return this.cache.readQuery(options, optimistic);
  };

  ApolloClient.prototype.readFragment = function (options, optimistic) {
    if (optimistic === void 0) {
      optimistic = false;
    }

    return this.cache.readFragment(options, optimistic);
  };

  ApolloClient.prototype.writeQuery = function (options) {
    var result = this.cache.writeQuery(options);
    this.queryManager.broadcastQueries();
    return result;
  };

  ApolloClient.prototype.writeFragment = function (options) {
    var result = this.cache.writeFragment(options);
    this.queryManager.broadcastQueries();
    return result;
  };

  ApolloClient.prototype.writeData = function (options) {
    var result = this.cache.writeData(options);
    this.queryManager.broadcastQueries();
    return result;
  };

  ApolloClient.prototype.__actionHookForDevTools = function (cb) {
    this.devToolsHookCb = cb;
  };

  ApolloClient.prototype.__requestRaw = function (payload) {
    return execute(this.link, payload);
  };

  ApolloClient.prototype.initQueryManager = function () {
    return this.queryManager;
  };

  ApolloClient.prototype.resetStore = function () {
    var _this = this;

    return Promise.resolve().then(function () {
      return _this.queryManager.clearStore();
    }).then(function () {
      return Promise.all(_this.resetStoreCallbacks.map(function (fn) {
        return fn();
      }));
    }).then(function () {
      return _this.reFetchObservableQueries();
    });
  };

  ApolloClient.prototype.clearStore = function () {
    var _this = this;

    return Promise.resolve().then(function () {
      return _this.queryManager.clearStore();
    }).then(function () {
      return Promise.all(_this.clearStoreCallbacks.map(function (fn) {
        return fn();
      }));
    });
  };

  ApolloClient.prototype.onResetStore = function (cb) {
    var _this = this;

    this.resetStoreCallbacks.push(cb);
    return function () {
      _this.resetStoreCallbacks = _this.resetStoreCallbacks.filter(function (c) {
        return c !== cb;
      });
    };
  };

  ApolloClient.prototype.onClearStore = function (cb) {
    var _this = this;

    this.clearStoreCallbacks.push(cb);
    return function () {
      _this.clearStoreCallbacks = _this.clearStoreCallbacks.filter(function (c) {
        return c !== cb;
      });
    };
  };

  ApolloClient.prototype.reFetchObservableQueries = function (includeStandby) {
    return this.queryManager.reFetchObservableQueries(includeStandby);
  };

  ApolloClient.prototype.extract = function (optimistic) {
    return this.cache.extract(optimistic);
  };

  ApolloClient.prototype.restore = function (serializedState) {
    return this.cache.restore(serializedState);
  };

  ApolloClient.prototype.addResolvers = function (resolvers) {
    this.localState.addResolvers(resolvers);
  };

  ApolloClient.prototype.setResolvers = function (resolvers) {
    this.localState.setResolvers(resolvers);
  };

  ApolloClient.prototype.getResolvers = function () {
    return this.localState.getResolvers();
  };

  ApolloClient.prototype.setLocalStateFragmentMatcher = function (fragmentMatcher) {
    this.localState.setFragmentMatcher(fragmentMatcher);
  };

  return ApolloClient;
}();

export { ApolloError as A, NetworkStatus as N, ApolloClient as a };
