import gql from '../../../_snowpack/pkg/graphql-tag.js';
export const GET_WIZARD_DATA = gql`
  query GET_WIZARD_DATA($tenantId: ID!, $today: DateTime!, $language: String!) {
    pipeline {
      getMany(tenantId: $tenantId, first: 1) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
    tenant {
      get(id: $tenantId) {
        createdAt
        metrics {
          items {
            documents: count(end: $today, type: document)
            products: count(end: $today, type: product)
          }
          webhooks {
            count(end: $today)
          }
          apiCalls {
            count(end: $today)
          }
          orders {
            count(end: $today)
          }
        }
        shapes {
          identifier
          type
          name
          createdAt
          updatedAt
        }
        grids(language: $language) {
          id
        }
        topics(language: $language) {
          id
        }
        webhooks {
          id
        }
      }
    }
  }
`;