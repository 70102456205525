import BubbleLanguage from './components/bubble-language.js';
import Dots from './components/dots.js';
import EmptyCalendar from './components/empty-calendar.js';
import GraphqlExplorer from './components/graphql-explorer.js';
import Hook from './components/hook.js';
import LockClosed from './components/lock-closed.js';
import People from './components/people.js';
import Percentage from './components/percentage.js';
import PlansAndPricing from './components/plans-and-pricing.js';
import PriceTag from './components/price-tag.js';
import Report from './components/report.js';
import Shapes from './components/shapes.js';
import UsageMeter from './components/usage-meter.js';
import UserCard from './components/user-card.js';
export const IconNew = {
  BubbleLanguage,
  Dots,
  EmptyCalendar,
  GraphqlExplorer,
  Hook,
  LockClosed,
  People,
  Percentage,
  PlansAndPricing,
  PriceTag,
  Report,
  Shapes,
  UsageMeter,
  UserCard
};