import styled from '../../../_snowpack/pkg/styled-components.js';
export const Wrapper = styled.div.withConfig({
  displayName: "styles__Wrapper",
  componentId: "sc-14gu1oo-0"
})(["--radius:4px;align-items:center;background-color:#f3f4f6;display:flex;flex-direction:column;height:100vh;overflow:auto;padding:2.4rem 4.5rem;h1{margin-top:auto;}button{cursor:pointer;margin:0;&:active{transform:scale(0.95);}}p{font-style:normal;font-weight:normal;font-size:14px;line-height:132.7%;color:#4d525b;&.italic{font-style:italic;margin-bottom:3em;}> a{text-decoration-color:#c2e5e1;text-underline-offset:8px;text-decoration-thickness:2px;}}"]);
export const Inner = styled.div.withConfig({
  displayName: "styles__Inner",
  componentId: "sc-14gu1oo-1"
})(["margin:auto 0;max-width:65ch;"]);
export const List = styled.ul.withConfig({
  displayName: "styles__List",
  componentId: "sc-14gu1oo-2"
})(["list-style:none;margin:0 0 2rem;padding:0;display:grid;grid-template-columns:repeat(2,1fr);gap:1rem;"]);
export const ListItem = styled.li.withConfig({
  displayName: "styles__ListItem",
  componentId: "sc-14gu1oo-3"
})(["box-shadow:0 2px 4px rgba(130,138,144,0.15);button{align-items:center;background-color:#fff;border-radius:var(--radius);border:none;color:#4d525b;display:flex;font-size:1em;font-style:normal;font-weight:500;gap:2em;line-height:132.7%;padding:0.5em 1em;white-space:nowrap;width:100%;height:100%;img{max-width:48px;}}"]);
export const Form = styled.form.withConfig({
  displayName: "styles__Form",
  componentId: "sc-14gu1oo-4"
})([""]);
export const Label = styled.label.withConfig({
  displayName: "styles__Label",
  componentId: "sc-14gu1oo-5"
})(["height:50px;display:flex;justify-content:space-between;background-color:#fff;padding:0 0 0 2em;gap:2em;align-items:center;box-shadow:0 2px 4px rgba(130,138,144,0.15);border-radius:var(--radius);input{border:0;outline:none;padding:0;font-size:1.15rem;box-shadow:none;-webkit-box-shadow:none;line-height:1.5;flex:1 1 auto;border-bottom:2px solid transparent;&:focus{border-color:#c2e5e1;}&::placeholder{font-style:italic;font-weight:normal;font-size:14px;color:#acacac;}}button{align-items:center;background-color:#c2e5e1;border-radius:0 var(--radius) var(--radius) 0;border:none;color:#4d525b;display:flex;font-size:14px;font-style:normal;font-weight:500;height:100%;justify-content:center;line-height:132.7%;padding:0 1.5em;position:relative;&[disabled]{color:transparent;}span{position:absolute;top:0;left:0;width:100%;height:100%;display:flex;color:#4d525b;align-items:center;justify-content:center;svg{height:1.2em;}}}"]);
export const LogoLink = styled.a.withConfig({
  displayName: "styles__LogoLink",
  componentId: "sc-14gu1oo-6"
})(["align-self:flex-start;svg{max-height:47px;}"]);
export const Title = styled.h1.withConfig({
  displayName: "styles__Title",
  componentId: "sc-14gu1oo-7"
})(["font-family:Roboto Slab;font-weight:bold;font-size:45px;line-height:1;color:#4d525b;margin:0 0 35px;"]);
export const Headline = styled.h2.withConfig({
  displayName: "styles__Headline",
  componentId: "sc-14gu1oo-8"
})(["font-family:Roboto;font-style:italic;font-weight:normal;font-size:19px;line-height:132.7%;color:#4d525b;margin:1.5em 0;"]);
export const Divider = styled.div.withConfig({
  displayName: "styles__Divider",
  componentId: "sc-14gu1oo-9"
})(["margin:2rem 0;display:flex;align-items:center;gap:1em;.line{height:1px;background-color:#e3e3e3;flex-grow:1;}"]);
export const DialogWrapper = styled.div.withConfig({
  displayName: "styles__DialogWrapper",
  componentId: "sc-14gu1oo-10"
})(["display:flex;flex-direction:column;gap:3rem;margin:-1em -1em -2.5em;h1{font-style:normal;font-weight:bold;font-size:30px;line-height:96.19%;color:#4d525b;}p{font-style:italic;font-weight:normal;font-size:16px;line-height:132.7%;color:#4d525b;}button{font-size:12px;line-height:14px;color:#4d525b;margin-left:auto;cursor:pointer;border:none;border-radius:4px;padding:1em 2em;background-color:transparent;position:relative;&:hover{background-color:#c2e5e1;}&[disabled]{color:transparent;}span{position:absolute;top:0;left:0;width:100%;height:100%;display:flex;color:#4d525b;align-items:center;justify-content:center;svg{height:1.2em;}}}.content{display:flex;align-items:center;gap:5em;max-width:65ch;}img{max-width:80px;}"]);