import { b as __assign, a as __extends, _ as __rest } from './common/tslib.es6-db19acae.js';
import { A as ApolloLink, f as fromError, O as Observable } from './common/bundle.esm-51e746f6.js';
import { p as print } from './common/printer-a4453ff5.js';
import { I as InvariantError } from './common/bundle.esm-b46b366e.js';
import './common/_commonjsHelpers-37fa8da4.js';
import './common/visitor-781df79b.js';
import './common/ast-9ee6b6e8.js';
import './common/blockString-e2c74d68.js';
import './common/process-2545f00a.js';

var defaultHttpOptions = {
  includeQuery: true,
  includeExtensions: false
};
var defaultHeaders = {
  accept: '*/*',
  'content-type': 'application/json'
};
var defaultOptions = {
  method: 'POST'
};
var fallbackHttpConfig = {
  http: defaultHttpOptions,
  headers: defaultHeaders,
  options: defaultOptions
};

var throwServerError = function (response, result, message) {
  var error = new Error(message);
  error.name = 'ServerError';
  error.response = response;
  error.statusCode = response.status;
  error.result = result;
  throw error;
};

var parseAndCheckHttpResponse = function (operations) {
  return function (response) {
    return response.text().then(function (bodyText) {
      try {
        return JSON.parse(bodyText);
      } catch (err) {
        var parseError = err;
        parseError.name = 'ServerParseError';
        parseError.response = response;
        parseError.statusCode = response.status;
        parseError.bodyText = bodyText;
        return Promise.reject(parseError);
      }
    }).then(function (result) {
      if (response.status >= 300) {
        throwServerError(response, result, "Response not successful: Received status code " + response.status);
      }

      if (!Array.isArray(result) && !result.hasOwnProperty('data') && !result.hasOwnProperty('errors')) {
        throwServerError(response, result, "Server response was missing for query '" + (Array.isArray(operations) ? operations.map(function (op) {
          return op.operationName;
        }) : operations.operationName) + "'.");
      }

      return result;
    });
  };
};

var checkFetcher = function (fetcher) {
  if (!fetcher && typeof fetch === 'undefined') {
    throw new InvariantError(1) ;
  }
};

var createSignalIfSupported = function () {
  if (typeof AbortController === 'undefined') return {
    controller: false,
    signal: false
  };
  var controller = new AbortController();
  var signal = controller.signal;
  return {
    controller: controller,
    signal: signal
  };
};

var selectHttpOptionsAndBody = function (operation, fallbackConfig) {
  var configs = [];

  for (var _i = 2; _i < arguments.length; _i++) {
    configs[_i - 2] = arguments[_i];
  }

  var options = __assign({}, fallbackConfig.options, {
    headers: fallbackConfig.headers,
    credentials: fallbackConfig.credentials
  });

  var http = fallbackConfig.http;
  configs.forEach(function (config) {
    options = __assign({}, options, config.options, {
      headers: __assign({}, options.headers, config.headers)
    });
    if (config.credentials) options.credentials = config.credentials;
    http = __assign({}, http, config.http);
  });
  var operationName = operation.operationName,
      extensions = operation.extensions,
      variables = operation.variables,
      query = operation.query;
  var body = {
    operationName: operationName,
    variables: variables
  };
  if (http.includeExtensions) body.extensions = extensions;
  if (http.includeQuery) body.query = print(query);
  return {
    options: options,
    body: body
  };
};

var serializeFetchParameter = function (p, label) {
  var serialized;

  try {
    serialized = JSON.stringify(p);
  } catch (e) {
    var parseError = new InvariantError(2) ;
    parseError.parseError = e;
    throw parseError;
  }

  return serialized;
};

var selectURI = function (operation, fallbackURI) {
  var context = operation.getContext();
  var contextURI = context.uri;

  if (contextURI) {
    return contextURI;
  } else if (typeof fallbackURI === 'function') {
    return fallbackURI(operation);
  } else {
    return fallbackURI || '/graphql';
  }
};

var createHttpLink = function (linkOptions) {
  if (linkOptions === void 0) {
    linkOptions = {};
  }

  var _a = linkOptions.uri,
      uri = _a === void 0 ? '/graphql' : _a,
      fetcher = linkOptions.fetch,
      includeExtensions = linkOptions.includeExtensions,
      useGETForQueries = linkOptions.useGETForQueries,
      requestOptions = __rest(linkOptions, ["uri", "fetch", "includeExtensions", "useGETForQueries"]);

  checkFetcher(fetcher);

  if (!fetcher) {
    fetcher = fetch;
  }

  var linkConfig = {
    http: {
      includeExtensions: includeExtensions
    },
    options: requestOptions.fetchOptions,
    credentials: requestOptions.credentials,
    headers: requestOptions.headers
  };
  return new ApolloLink(function (operation) {
    var chosenURI = selectURI(operation, uri);
    var context = operation.getContext();
    var clientAwarenessHeaders = {};

    if (context.clientAwareness) {
      var _a = context.clientAwareness,
          name_1 = _a.name,
          version = _a.version;

      if (name_1) {
        clientAwarenessHeaders['apollographql-client-name'] = name_1;
      }

      if (version) {
        clientAwarenessHeaders['apollographql-client-version'] = version;
      }
    }

    var contextHeaders = __assign({}, clientAwarenessHeaders, context.headers);

    var contextConfig = {
      http: context.http,
      options: context.fetchOptions,
      credentials: context.credentials,
      headers: contextHeaders
    };

    var _b = selectHttpOptionsAndBody(operation, fallbackHttpConfig, linkConfig, contextConfig),
        options = _b.options,
        body = _b.body;

    var controller;

    if (!options.signal) {
      var _c = createSignalIfSupported(),
          _controller = _c.controller,
          signal = _c.signal;

      controller = _controller;
      if (controller) options.signal = signal;
    }

    var definitionIsMutation = function (d) {
      return d.kind === 'OperationDefinition' && d.operation === 'mutation';
    };

    if (useGETForQueries && !operation.query.definitions.some(definitionIsMutation)) {
      options.method = 'GET';
    }

    if (options.method === 'GET') {
      var _d = rewriteURIForGET(chosenURI, body),
          newURI = _d.newURI,
          parseError = _d.parseError;

      if (parseError) {
        return fromError(parseError);
      }

      chosenURI = newURI;
    } else {
      try {
        options.body = serializeFetchParameter(body, 'Payload');
      } catch (parseError) {
        return fromError(parseError);
      }
    }

    return new Observable(function (observer) {
      fetcher(chosenURI, options).then(function (response) {
        operation.setContext({
          response: response
        });
        return response;
      }).then(parseAndCheckHttpResponse(operation)).then(function (result) {
        observer.next(result);
        observer.complete();
        return result;
      }).catch(function (err) {
        if (err.name === 'AbortError') return;

        if (err.result && err.result.errors && err.result.data) {
          observer.next(err.result);
        }

        observer.error(err);
      });
      return function () {
        if (controller) controller.abort();
      };
    });
  });
};

function rewriteURIForGET(chosenURI, body) {
  var queryParams = [];

  var addQueryParam = function (key, value) {
    queryParams.push(key + "=" + encodeURIComponent(value));
  };

  if ('query' in body) {
    addQueryParam('query', body.query);
  }

  if (body.operationName) {
    addQueryParam('operationName', body.operationName);
  }

  if (body.variables) {
    var serializedVariables = void 0;

    try {
      serializedVariables = serializeFetchParameter(body.variables, 'Variables map');
    } catch (parseError) {
      return {
        parseError: parseError
      };
    }

    addQueryParam('variables', serializedVariables);
  }

  if (body.extensions) {
    var serializedExtensions = void 0;

    try {
      serializedExtensions = serializeFetchParameter(body.extensions, 'Extensions map');
    } catch (parseError) {
      return {
        parseError: parseError
      };
    }

    addQueryParam('extensions', serializedExtensions);
  }

  var fragment = '',
      preFragment = chosenURI;
  var fragmentStart = chosenURI.indexOf('#');

  if (fragmentStart !== -1) {
    fragment = chosenURI.substr(fragmentStart);
    preFragment = chosenURI.substr(0, fragmentStart);
  }

  var queryParamsPrefix = preFragment.indexOf('?') === -1 ? '?' : '&';
  var newURI = preFragment + queryParamsPrefix + queryParams.join('&') + fragment;
  return {
    newURI: newURI
  };
}

var HttpLink = function (_super) {
  __extends(HttpLink, _super);

  function HttpLink(opts) {
    return _super.call(this, createHttpLink(opts).request) || this;
  }

  return HttpLink;
}(ApolloLink);

export { HttpLink };
