import { useContext, useEffect, useState } from '../../_snowpack/pkg/react.js';
import config from '../app-config.js';
import { UserContext } from './user-context.js';
import { getActiveSubscriptionContractId, getActiveSubscriptionPlanName } from '../views/settings/pricing-plans/create-crystallize-subscription/utils.js';

async function getSubscriptionContracts(customerIdentifier) {
  var _json$data, _json$data$subscripti;

  const query = `
    query GET_ALL_SUBSCRIPTIONS($customerIdentifier: String!){
      subscriptions {
        getAll(customerIdentifier: $customerIdentifier)
      }
    }
  `;
  const response = await fetch(config.CRYSTALLIZE_SHOP_GRAPHQL_SERVICE_API, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      query,
      variables: {
        customerIdentifier
      }
    })
  });
  const json = await response.json();

  if (json.errors) {
    throw new Error(json.errors);
  }

  return ((_json$data = json.data) === null || _json$data === void 0 ? void 0 : (_json$data$subscripti = _json$data.subscriptions) === null || _json$data$subscripti === void 0 ? void 0 : _json$data$subscripti.getAll) || [];
}

export function useTenantPlan() {
  const [status, setStatus] = useState('loading');
  const [planName, setPlanName] = useState('');
  const [hasActivated, setHasActivated] = useState(false);
  const {
    tenant
  } = useContext(UserContext);
  useEffect(() => {
    let retryTimeout;

    (async function get(isARetry) {
      setStatus('loading');

      try {
        const result = await getSubscriptionContracts(tenant.identifier);
        setPlanName(getActiveSubscriptionPlanName(result));
        setHasActivated(getActiveSubscriptionContractId(result) ? true : false);
      } catch (e) {
        // In case we don't know, let's assume they activated
        setHasActivated(true);

        if (!isARetry) {
          retryTimeout = setTimeout(() => get(true), 1000);
        }
      }

      setStatus('loaded');
    })();

    return () => clearTimeout(retryTimeout);
  }, [tenant.identifier]);
  return {
    status,
    planName,
    hasActivated
  };
}