import { useState } from '../../_snowpack/pkg/react.js';

function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key); // Parse stored json or if none return initialValue

      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.error(error);
      return initialValue;
    }
  }); // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.

  const setValue = value => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;

      if (valueToStore != null) {
        // Save state
        // Save to local storage
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      } else {
        // Save to local storage
        window.localStorage.removeItem(key);
      }

      setStoredValue(valueToStore);
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.error(error);
    }
  };

  return [storedValue, setValue];
}

export default useLocalStorage;