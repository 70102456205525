import { useQuery } from '../../_snowpack/pkg/@apollo/react-hooks.js';
import { GET_TENANT_LANGUAGES } from '../queries/index.js';
import useTenant from './useTenant.js';

function useTenantLanguages() {
  var _data$tenant$get;

  const {
    tenant: {
      id
    }
  } = useTenant();
  const {
    error,
    data
  } = useQuery(GET_TENANT_LANGUAGES, {
    variables: {
      id
    }
  });
  const {
    availableLanguages
  } = (_data$tenant$get = data === null || data === void 0 ? void 0 : data.tenant.get) !== null && _data$tenant$get !== void 0 ? _data$tenant$get : {};

  if (error) {
    console.error('Could not retrieve tenant languages');
  }

  return availableLanguages !== null && availableLanguages !== void 0 ? availableLanguages : [];
}

export default useTenantLanguages;