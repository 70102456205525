import styled from '../../../../_snowpack/pkg/styled-components.js';
import is from '../../../../_snowpack/pkg/styled-is.js';
import { responsive } from '../../../css/index.js';
import { motion } from '../../../../_snowpack/pkg/framer-motion.js';
export const NotAWizard = styled.section.withConfig({
  displayName: "styles__NotAWizard",
  componentId: "sc-7185jx-0"
})(["display:grid;grid-gap:25px;grid-template-columns:1fr;border-bottom:1px solid #dfdfdf;padding-bottom:35px;"]);
export const ProgressWrapper = styled.div.withConfig({
  displayName: "styles__ProgressWrapper",
  componentId: "sc-7185jx-1"
})(["padding:0 0;display:grid;grid-template-columns:1fr 1fr 1fr;grid-gap:25px;", "{grid-gap:10px;}", "{grid-gap:10px;grid-template-columns:1fr;}"], responsive.smAndLess, responsive.xs);
export const ProgressChart = styled.div.withConfig({
  displayName: "styles__ProgressChart",
  componentId: "sc-7185jx-2"
})(["position:relative;max-width:170px;margin:0 auto;display:block;"]);
export const ProgressLabel = styled.div.withConfig({
  displayName: "styles__ProgressLabel",
  componentId: "sc-7185jx-3"
})(["color:", ";position:absolute;left:50%;top:50%;font-size:13px;transform:translate(-50%,-40%);text-align:center;span{display:block;font-size:24px;font-weight:900;}"], ({
  theme
}) => theme.colors.label);
export const ProgressGroup = styled.div.withConfig({
  displayName: "styles__ProgressGroup",
  componentId: "sc-7185jx-4"
})(["display:grid;align-items:center;grid-template-columns:35% 1fr;padding:10px 25px 10px 0;@media (min-width:1024px) and (max-width:1400px){grid-template-columns:1fr;padding:10px 25px 10px 25px;}", "{padding:10px 25px 10px 25px;grid-template-columns:1fr;}"], responsive.smAndLess);
export const ProgressAnchor = styled.a.withConfig({
  displayName: "styles__ProgressAnchor",
  componentId: "sc-7185jx-5"
})(["display:grid;grid-template-columns:30px 100%;padding:5px 0 5px 15px;cursor:pointer;align-items:center;position:relative;span{font-size:14px;font-weight:400;text-decoration:", ";color:", ";position:relative;display:block;}"], p => p.isCompleted ? 'line-through' : 'none', ({
  theme
}) => theme.colors.label);
export const CardLabel = styled.div.withConfig({
  displayName: "styles__CardLabel",
  componentId: "sc-7185jx-6"
})(["position:absolute;left:35px;top:35px;color:", ";font-size:16px;font-weight:500;"], ({
  theme
}) => theme.colors.label);
export const SliderCard = styled(motion.div).withConfig({
  displayName: "styles__SliderCard",
  componentId: "sc-7185jx-7"
})(["width:100%;height:100%;position:absolute;top:0;cursor:grab;"]);
export const SliderCardInner = styled(motion.div).withConfig({
  displayName: "styles__SliderCardInner",
  componentId: "sc-7185jx-8"
})(["width:100%;height:100%;background:#fff;border-radius:8px;position:relative;z-index:10;padding:50px 100px;grid-gap:50px;box-shadow:2px 2px 4px rgba(0,0,0,0.1);display:grid;grid-template-columns:2fr 1fr;align-items:center;p{color:", ";line-height:1.8em;font-size:18px;margin-bottom:35px;}h2{font-size:2.5rem;color:", ";display:flex;}img{width:100%;pointer-events:none;}", "{padding:10px 35px;grid-template-columns:1fr;display:flex;flex-direction:column-reverse;justify-content:center;align-items:center;h2{display:block;text-align:center;font-size:1.4rem;}p{text-align:center;font-size:1rem;}img{max-width:200px;}a{display:flex;justify-content:center;}}", "{h2{font-size:1.2rem;}p{font-size:0.9rem;}img{max-width:100px;}}"], ({
  theme
}) => theme.colors.label, ({
  theme
}) => theme.colors.label, responsive.smAndLess, responsive.xs);
export const SliderWrapper = styled(motion.div).withConfig({
  displayName: "styles__SliderWrapper",
  componentId: "sc-7185jx-9"
})(["width:100%;height:100%;min-height:500px;position:relative;", "{min-height:700px;}", "{min-height:600px;}"], responsive.smAndLess, responsive.xs);
export const DotIndicators = styled.div.withConfig({
  displayName: "styles__DotIndicators",
  componentId: "sc-7185jx-10"
})(["position:absolute;z-index:999;bottom:30px;left:50%;transform:translateX(-50%);display:flex;align-items:center;", "{bottom:15px;}", "{display:none;}"], responsive.smAndLess, responsive.xs);
export const Dot = styled.div.withConfig({
  displayName: "styles__Dot",
  componentId: "sc-7185jx-11"
})(["width:40px;margin:0;height:40px;background:#fff;border-radius:50%;display:flex;align-items:center;justify-content:center;transform:scale(1,1);&:after{content:'';bottom:0;width:50%;height:3px;background:#dfdfdf;position:absolute;transition:all 0.2s ease-in-out;}&:hover{cursor:pointer;background:rgba(0,0,0,0.05);}", " ", ""], is('done')`
    &:after {
      background:#C2E5E1;
    }
  `, is('active')`
    &:after {
      bottom:-5px;
    }
  `);
export const DotSlideButton = styled.button.withConfig({
  displayName: "styles__DotSlideButton",
  componentId: "sc-7185jx-12"
})(["background:transparent;border:none;font-size:20px;margin:0 20px;color:", ";&:hover{color:", ";cursor:pointer;}"], ({
  theme
}) => theme.colors.label, ({
  theme
}) => theme.colors.glacier);
export const CheckMark = styled.span.withConfig({
  displayName: "styles__CheckMark",
  componentId: "sc-7185jx-13"
})(["border-radius:50%;width:18px;background:", ";height:18px;position:relative;svg{left:3px;top:-2px;position:absolute;transform:rotate(10deg);}"], p => p.isCompleted ? '#C2E5E1' : '#dfdfdf');