function dPush(name) {
  return props => window.dataLayer.push({
    event: name,
    ...props
  });
}

if (!window.dataLayer) {
  window.dataLayer = [];
}

export default {
  setCustomReferrer(customReferrer) {
    window.dataLayer.push({
      customReferrer
    });
  },

  trigger: {
    termsAgreed: dPush('terms_agreed'),
    tenantCreated: dPush('tenant_created')
  }
};