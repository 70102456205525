import gql from '../../_snowpack/pkg/graphql-tag.js';
const PriceVariantFragment = gql`
  fragment PriceVariantFragment on PriceVariant {
    name
    identifier
    currency
  }
`;
export const GET_PRICE_VARIANTS = gql`
  query GET_PRICE_VARIANTS($tenantId: ID!) {
    priceVariant {
      getMany(tenantId: $tenantId) {
        ...PriceVariantFragment
      }
    }
  }

  ${PriceVariantFragment}
`;