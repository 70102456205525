import gql from '../../../../../_snowpack/pkg/graphql-tag.js';
export const SEARCH = gql`
  query SEARCH($tenantId: ID!, $language: String!, $searchTerm: String, $types: [SuggestSearchItemType!], $first: Int) {
    search {
      suggest(tenantId: $tenantId, language: $language, searchTerm: $searchTerm, types: $types, first: $first) {
        aggregations {
          typesAggregation {
            type
            count
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
          totalNodes
        }
        edges {
          cursor
          node {
            id
            name
            type
            path
          }
        }
      }
    }
  }
`;