import { _ as _getNative } from './_Map-789e2d23.js';

var defineProperty = function () {
  try {
    var func = _getNative(Object, 'defineProperty');
    func({}, '', {});
    return func;
  } catch (e) {}
}();

var _defineProperty = defineProperty;

export { _defineProperty as _ };
